import React, { useEffect} from 'react';
import './LeavingModal.css';
import {useGameManager} from '../context/GameManager';
import Sad from '../assets/RoboKeisi/Leaving.svg';
import X from '../assets/Icons/XIcon.svg';

// eslint-disable-next-line react/prop-types
const LeavingModal = ({ isOpen,onClose}) => {
  const {setIsTimerRunning, restartGame} = useGameManager();

  useEffect(()=>{
    if(isOpen) {

      setIsTimerRunning(false)
    }
  },[isOpen])

  const leaveGame = () => {
    restartGame();
    window.location.href = '/contact';
  };
  const closeModal = () => {
    onClose(false);
    setIsTimerRunning(true)
  }
  return (
    <>
      {isOpen && (
        <div className={`modal-overlay roadRunnerIn}`}>
          <div className="leave-modal">
            <div className="leaving-modal-title">
              <div></div>
              <span>Leaving so soon</span>
              <img src={X} onClick={closeModal}/>
            </div>
            <hr className="leaving-divider" />
            <img src={Sad} alt="" className="keisi" />
            <div className="leaving-info">
              <div className='leav-info-text'>

              <span>Are you sure you want to leave?</span>
              <span>
                You can still apply, but we would like you to participate on
                this.
              </span>
              </div>
              <hr className="footer-divider" />
              <div className="leave-buttons">
                <button className="cnt-btn" onClick={closeModal}>Continue game</button>
                <button className="leave-button" onClick={leaveGame}>
                  Yes, quit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeavingModal;
