import Colors from "../../utils/colors/Colors"

const ButtonStyles = {
    desktop: {
        defaultProps: {
            display: 'flex',
            width: '100%',
            height: 40,
            padding: '0px 10px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            boxShadow: '3px 3px 0px 0px #000',
            border: '1px solid #000',
            background: Colors.white,
            cursor: 'pointer',
        },
        filledStyle: {
            background: Colors.purpleC,
            color: Colors.white
        },
        borderStyle: {
            border: '2px solid #000',
        },
        textColor: {
            color: Colors.lightGreyC,
        }
    },
    laptop: {
        defaultProps: {
            display: 'flex',
            width:'100%',
            height: 40,
            padding: '0px 10px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            boxShadow: '3px 3px 0px 0px #000',
            border: '1px solid #000',
            background: Colors.white,
            cursor: 'pointer',
        },
        filledStyle: {
            background: Colors.purpleC,
            color: Colors.white
        },
        borderStyle: {
            border: '2px solid #000',
        },
        textColor: {
            color: Colors.lightGreyC,
        }

    },
    mobile: {
        defaultProps: {
            display: 'flex',
            width: '100%',
            height: 46,
            padding: '0px 10px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            boxShadow: '3px 3px 0px 0px #000',
            border: '1px solid #000',
            background: Colors.white,
            cursor: 'pointer',
        },
        filledStyle: {
            background: Colors.purpleC,
            color: Colors.white
        },
        borderStyle: {
            border: '2px solid #000',
        },
        textColor: {
            color: Colors.lightGreyC,
        }
    }
}

export default ButtonStyles