import React from 'react'
import PropTypes from 'prop-types'
import TextAreaStyles from './TextArea.Styles'
import '../InputField/inputField.css'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const TextArea = (props) => {
  const Dimension = useWindowDimensions()
  const styles = Dimension > 1023 ? { ...TextAreaStyles.desktop } : { ...TextAreaStyles.mobile }

  const {
    placeHolder,
    onChange,
    value,
    style
  } = props

  const allStyles = Object.assign({},
    styles.layout
    , style
  );


  return (
    <textarea placeholder={placeHolder} onChange={onChange} value={value} style={allStyles} className='removeOutline' />
  )
}

TextArea.propTypes = {
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}

export default React.memo(TextArea)