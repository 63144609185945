import BackgroundPhoto from '../../utils/images/Background.svg'
// import BackgroundMobileAbout from '../../utils/images/BackgroundMobileAbout.svg'


const OurProgramStyles = {
    desktop: {
        wrapper: {
            width: 'calc(100% - 455px)',
            display: 'flex',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundPosition:'center',
            backgroundRepeat:'repeat',
            objectFit: 'cover',
            flexDirection: 'column',
            gap: 32,
            padding: '100px 100px 32px 100px',
        },
        wrapper2: {
            width: 'calc(100% - 455px)',
            display: 'flex',
            height: 'fit-content',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundPosition:'center',
            backgroundRepeat:'repeat',
            objectFit: 'cover',
            flexDirection: 'column',
            gap: 32,
            padding: '100px 100px 32px 100px',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '70px'
        },
        taleasLogo: {
            width: '75px',
            height: '75px'
        },
        WowLogo: {
            width: 21,
            height: 'auto',
            marginLeft: '-2%',
            padding: '16px 0px'
        }
    },
    laptop: {
        wrapper: {
            width: 'calc(100% - 345px)',
            display: 'flex',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundPosition:'center',
            backgroundRepeat:'repeat',
            objectFit: 'cover',
            flexDirection: 'column',
            overflow: 'scroll',
            gap: 32,
            padding: '64px',
        },
        wrapper2: {
            width: 'calc(100% - 345px)',
            display: 'flex',
            height: 'fit-content',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundPosition:'center',
            backgroundRepeat:'repeat',
            objectFit: 'cover',
            flexDirection: 'column',
            gap: 32,
            padding: '64px',
        },

        header: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '48px'
        },
        taleasLogo: {
            width: '75px',
            height: '75px'
        },
        WowLogo: {
            width: 21,
            height: 65,
            marginLeft: '-2%',
            padding: '16px 0px'

        }
    },
    tablet: {
        wrapper: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            overflowX: 'hidden',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            objectFit: 'cover',
            gap: 16,
            padding: 16
        },
        wrapper2: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            overflowX: 'hidden',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            objectFit: 'cover',
            gap: 16,
            padding: 16
        },
        container: {
            display: 'flex',
            flex: 1,
            flexDirection: "column",
            marginBottom: 88
        },
        header: {
            marginBottom: 16
        },
        topIconsRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '32px 0px 24px 0px'
        },
        taleasLogo: {
            width: 60,
            height: 'auto',
        },
        navbarOpener: {
            width: 42,
            height: 'auto',
        },
        WowLogo: {
            width: 13,
            height: 42,
            marginTop: '-3%',
            transform: 'rotate(10deg)'
        },
        CardProgramContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            marginTop: 32,
            justifyContent: 'center',
            width: '60%',
            alignSelf: 'center'
        }

    },
    mobile: {
        wrapper: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            padding: '0px 16px',
            overflowX: 'hidden',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            objectFit: 'cover',
            gap: 16,
        },
        wrapper2: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            padding: '0px 16px',
            overflowX: 'hidden',
            backgroundImage: `url('${BackgroundPhoto}')`,
            objectFit: 'cover',
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            gap: 16,
        },
        container: {
            display: 'flex',
            flex: 1,
            flexDirection: "column",
        },
        topIconsRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: 24,
            marginBottom: 8
        },
        header: {
            marginBottom: 16
        },
        taleasLogo: {
            width: 60,
            height: 'auto',
        },
        navbarOpener: {
            width: 42,
            height: 'auto',
        },
        WowLogo: {
            width: 13,
            height: 42,
            marginTop: '-3%',
            transform: 'rotate(10deg)'
        },
        CardProgramContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
        }
    }
}

export default OurProgramStyles