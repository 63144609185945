const ExpandableTextFieldStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 17,
        zIndex: 2,
    },
    defaultStyles: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        cursor: 'pointer'
    },
    rowContainer: (gap) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: gap
    }),
    downArrow: {
        width: 32,
        height: 32,
    },
    upArrow: {
        width: 32,
        height: 32,
        transform: 'rotate(180deg)',
    },
    answerStyles: {
        lineHeight: 1.5,
        marginBottom: 12,
    },
    animationIn: {
        'transition-timing-function': 'ease-in',
        transition: '0.2s',
        transform: 'translateY(130 %)',
    },
    animationOut: {
        'transition-timing-function': 'ease-out',
        transition: '0.25s',
        transform: 'translateY(0)'
    }

}

export default ExpandableTextFieldStyles