import axios from "axios"
import { retrieveUtilities } from "../ServiceUtils/Utils"
import { isFunction as _isFunction } from 'lodash'


export const contactInformation = async (_data, utilities = {}) => {
    const _utilities = retrieveUtilities(utilities)

    const url = 'https://qsw8nrhwvb.execute-api.eu-central-1.amazonaws.com/prod/submitForm'

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

    onStart()

    const data = _data
    const headers = {

    }

    try {
        const response = await axios.post(url, data, {
            headers,
        })

        const _response = responseMapper(response)
        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)
        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}


export const filePostService = async (_data, utilities = {}) => {
    const _utilities = retrieveUtilities(utilities)
    const url = 'https://qsw8nrhwvb.execute-api.eu-central-1.amazonaws.com/prod/getAttachmentsPresignedURL'

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

    onStart()

    const data = _data

    const headers = {
    }

    try {
        const response = await axios.post(url, data, {
            headers,
        })
        const _response = responseMapper(response)
        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)
        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}
export const imageService = async (_url, _data, utilities = {}) => {
    const _utilities = retrieveUtilities(utilities)

    const { onStart, onSuccess, onError, responseMapper, errorMapper } = _utilities

    onStart()

    const url = _url
    const data = _data

    try {
        const response = await axios.put(url,
            data
        )

        const _response = responseMapper(response)
        onSuccess(_response)
        return _response
    } catch (error) {
        const _error = errorMapper(error)
        if (_isFunction(onError)) {
            onError(_error)
        } else {
            throw _error
        }
    }
}