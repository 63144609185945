import React from 'react'
import Text from '../Text/Text'
import Button from '../Button/Button'
import NavbarStyles from './Navbar.Styles'
import Instagram from '../../utils/images/mdi_instagram.svg'
import closeIcon from '../../utils/images/closeIcon.svg'
import NavbarYellowWave from '../../utils/images/NavbarYellowWave.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import TaleasLogo from '../../utils/images/TaleasLogo.svg'
import Email from '../../utils/images/Email.svg'
import tegeriaLogo from '../../utils/images/tegeriaLogo.svg'
import '../index.css'

// eslint-disable-next-line react/prop-types
const Navbar = ({ setShowNavbar }) => {
  const navigate = useNavigate();
  const activeTab = useLocation()
  const Dimension = useWindowDimensions()


  const styles = Dimension.width > 1440 ? { ...NavbarStyles.desktop } : Dimension.width > 1023 && Dimension.width < 1441 ?
    { ...NavbarStyles.laptop } : Dimension.width > 767 && Dimension.width < 1024 ? { ...NavbarStyles.tablet } : { ...NavbarStyles.mobile }

  const handleInstagramRedirect = () => {
    window.open('https://www.instagram.com/taleas.io/?hl=en')
  }
  const handleTwitterRedirect = () => {
    window.open('mailto:applications@tegeria.com',"_self")
  }
  const handleTegeriaRedirect = () => {
    window.open('https://tegeria.com/')
  }


  const ContactUsNav = () => {
    return (
      <div style={styles.ContactUsNavStyle}>
        <Text text={'Contact Us'} style={styles.textSize} size={'h5'} />
        {/* <a href='mailto:info@tegeria.com' style={{ textDecoration: 'none', color: '#000', fontSize: '16px' }}>
          <Text text={'info@tegeria.com'} size={'body'} />
        </a> */}
        <div style={styles.socialsContainer}>
          <img src={Email} alt='twitter redirect icon' style={styles.img} onClick={() => handleTwitterRedirect()} />
          <img src={Instagram} alt='instagram redirect icon' style={styles.img} onClick={() => handleInstagramRedirect()} />
        </div>
      </div>
    )
  }


  const handleRoutingHome = () => {
    navigate('/');
    setShowNavbar(false)
  }
  const handleRoutingAbout = () => {
    navigate('/about');
    setShowNavbar(false)
  }
  const handleRoutingContact = () => {
    navigate('/mainmenu');
    setShowNavbar(false)
  }
  const handleRoutingApply = () => {
    navigate('/program');
    setShowNavbar(false)
  }


  const ContainerRouting = () => {
    return (
      <div style={styles.containerRoutingStyles}>
        {Dimension.width > 1023 ? <img src={TaleasLogo} alt='a logo that identifies taleas' style={styles.taleasLogo} onClick={handleRoutingHome} /> : null}
        <Button text={'Home'} textWeight={'regular'} onClick={() => handleRoutingHome()} isFilled={activeTab.pathname === '/' || (activeTab.pathname !== '/program' && activeTab.pathname !== '/about' && activeTab.pathname !== '/mainmenu' && activeTab.pathname !== '/game') ? true : false} isBorderThickened={activeTab.pathname === '/' ? true : false} />
        <Button text={'About us'} textWeight={'regular'} onClick={() => handleRoutingAbout()} isFilled={activeTab.pathname === '/about' ? true : false} isBorderThickened={activeTab.pathname === '/about' ? true : false} />
        <Button text={'Our Program'} textWeight={'regular'} onClick={() => handleRoutingApply()} isFilled={activeTab.pathname === '/program' ? true : false} isBorderThickened={activeTab.pathname === '/program' ? true : false} />
        <Button text={'Apply now'} onClick={() => handleRoutingContact()} isFilled={activeTab.pathname === '/mainmenu' || (activeTab.pathname==="/game")? true : false} isBorderThickened={activeTab.pathname === '/mainmenu' || (activeTab.pathname==="/game")? true : false} textWeight={'regular'} />
      </div>
    )
  }


  return (
    <div style={styles.container} className='wrapper'>
      {Dimension.width < 1024 ?
        <div style={styles.navHeaderIconContainer}>
          <img src={TaleasLogo} alt='a logo that identifies taleas' style={styles.lightBulb} />
          <img src={closeIcon} alt='a logo to help close the navigation menu' style={styles.closeIcon} onClick={() => setShowNavbar(false)} />
        </div>
        : null}
      <ContainerRouting />
      <ContactUsNav />
      <div style={styles.tegeriaContainer} onClick={handleTegeriaRedirect}>
        <Text text={'Powered by'} style={styles.textTegeria} />
        <img src={tegeriaLogo} style={styles.tegeriaLogo} />
      </div>
      {Dimension.width < 768 ?
       <div style={styles.imageContainer}>
       <img src={NavbarYellowWave} alt='a decorative wave in the end of navigation menu' style={styles.NavbarYellowWave} />
       </div>
       :
        <img src={NavbarYellowWave} alt='a decorative wave in the end of navigation menu' style={styles.NavbarYellowWave} />
       }
    </div>
  )
}

export default React.memo(Navbar)