/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react"
import { Circle, CircleDot} from "lucide-react"
import PropTypes from 'prop-types'
import "./image-slider.css"
import Arrow from '../../utils/images/arrow-default.png'
import ArrowHover from '../../utils/images/arrow-hover.png'

// type ImageSliderProps = {
//   images: {
//     url: string
//     alt: string
//   }[]
// }

export function ImageSlider({ images }) {
  const [imageIndex, setImageIndex] = useState(0)
  const [autoplay, setAutoplay] = useState(true)
  const [isHoveredFirst, setIsHoveredFirst] = useState(false)
  const [isHoveredSecond, setIsHoveredSecond] = useState(false)
  let timeOut = null

  function showNextImage() {
    setImageIndex(index => {
      if (index === images.length - 1) return 0
      return index + 1
    })
  }

  const slideRight = () => {
    setImageIndex(imageIndex === images.length - 1 ? 0 : imageIndex + 1);
  };

  useEffect(() => {
    timeOut =
      autoplay &&
      setTimeout(() => {
        slideRight();
      }, 2000);
  });

// const rotate =' transform: rotate(-0.25turn)'

  function showPrevImage() {
    setImageIndex(index => {
      if (index === 0) return images.length - 1
      return index - 1
    })
  }

  return (
    <section
      aria-label="Image Slider"
      style={{ width: "100%", height: "100%", position: "relative" }}
      onMouseEnter={() => {
        setAutoplay(false)
        clearTimeout(timeOut)
      }}
      onMouseLeave={() => setAutoplay(true)}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          overflow: "hidden",
          position: 'relative',
        }}
      >
        {images.map(({url, alt}, index) => (
          <img
            key={url}
            src={url}
            alt={alt}
            aria-hidden={imageIndex !== index}
            className="img-slider-img"
            style={{ translate: `${-100 * imageIndex}%` }}
          />
        ))}
      </div>
      {/* <button
        onClick={showPrevImage}
        className="img-slider-btn"
        style={{ left: -60 }}
        aria-label="View Previous Image"
      >
       <ArrowBigLeft aria-hidden />
      </button>
      <button
        onClick={showNextImage}
        className="img-slider-btn"
        style={{ right: -60 }}
        aria-label="View Next Image"
      >
        <ArrowBigRight aria-hidden />
      </button> */}
     
      <div
        style={{
          position: "absolute",
          paddingTop: 10,
          left: "50%",
          translate: "-50%",
          display: "flex",
          gap: ".5rem",
         
        }}
      >
        {images.map((_, index) => (
          <button
            key={index}
            className="img-slider-dot-btn"
            aria-label={`View Image ${index + 1}`}
            onClick={() => setImageIndex(index)}
          >
            {index === imageIndex ? (
              <CircleDot aria-hidden />
            ) : (
                <Circle aria-hidden />
            )}
          </button>
        ))}
         <div
        style={{
          position: "absolute",
          paddingTop: 25,
          left: "50%",
          translate: "-50%",
          display: "flex",
          gap: ".5rem",
        
        }}
      >
         <button
         onMouseEnter={() => setIsHoveredFirst(true)}
         onMouseLeave={() => setIsHoveredFirst(false)}
        onClick={showPrevImage}
        className="img-slider-btn"
        style={{ left: -130, top: -7 }}
        aria-label="View Previous Image"
      >
       <img src={isHoveredFirst ? ArrowHover : Arrow} style={{height:20, width:20, transform: isHoveredFirst && 'rotate(-0.5turn) '}} />
      </button>
      <button
       onMouseEnter={() => setIsHoveredSecond(true)}
       onMouseLeave={() => setIsHoveredSecond(false)}
        onClick={showNextImage}
        className="img-slider-btn"
        style={{ right: -130, top: -7 }}
        aria-label="View Next Image"
      >
       <img style={{height:20, width:20,transform: !isHoveredSecond && 'rotate(-0.5turn) '}} src={isHoveredSecond ? ArrowHover : Arrow} />
      </button>
      </div>
      </div>
      <div id="after-image-slider-controls" />
    </section>
  )
}

ImageSlider.propTypes = {
    images: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
  }