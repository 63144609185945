import React, { useEffect } from "react";
import "./Stopwatch.css";
import { useGameManager } from "../context/GameManager";
const Stopwatch = () => {
  const { time, setTime, isTimerRunning } = useGameManager();

  useEffect(() => {
    let intervalId;

    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isTimerRunning]);

  const formatTime = (timeInMilliseconds) => {
    const minutes = Math.floor(timeInMilliseconds / (60 * 1000));
    const seconds = Math.floor((timeInMilliseconds % (60 * 1000)) / 1000);
    const milliseconds = Math.floor((timeInMilliseconds % 1000) / 10);

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    const formattedMilliseconds = String(milliseconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
  };

  return (
    <div className="stopwatch">
      <span className="time-text">{formatTime(time)}</span>
    </div>
  );
};

export default Stopwatch;
