import Colors from "../../utils/colors/Colors"
import HomeBG from '../../utils/images/Background.svg'


const HomeStyles = {
    desktop: {
        wrapper: {
            width: 'calc(100% - 455px)',
            display: 'flex',
            padding: '100px 100px',
            backgroundImage: `url('${HomeBG}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            flexDirection: 'column',
            overflow: 'scroll',
        },
        wrapper2: {
            width: 'calc(100% - 393px)',
            display: 'flex',
            padding: '48px',
            backgroundImage: `url('${HomeBG}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            flexDirection: 'column',
            overflow: 'scroll'
        },
        firstFlexContainer: {
            display: 'flex',
        },
        textApplicationClosed: {
            lineHeight: 'normal'
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around'
        },
        textContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center'
        },
        biggerImage: {
            width: '80%'
        },
        imgContainer: {
            position: 'relative'
        },

        trippleLinesContainer: {
            position: 'relative',
            display: 'flex',
        },
        taleasLogo: {
            width: 75,
            height: 'auto',
        },
        rowContainerTaleas: {
            display: 'flex',
            flexDirection: 'row',
        },
        logoSize: {
            width: 50,
            height: 'auto'
        },
        boldTextContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 10
        },
        textFixedWidth: {
            width: 450,
            marginTop: 24,
            marginBottom: 48,
            lineHeight: 1.5
        },
        textWidth: {
            width: 'fit-content',
        },
        coloredContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
        },
        textColoredContainer: {
            fontSize: '48px'
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'max-content',
            alignSelf: 'center',
            maxWidth: 700,
        },
        image: {
            width: '70%',
            height: 'auto',
        },
        cardContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
        },
        applicationInsideCardContainer: {
            display: 'flex',
            height: '60px',
            padding: '16px',
            alignItems: 'center',
            flexShrink: 0,
            borderRadius: 6,
            border: '1px solid #000',
            background: Colors.white,
            boxShadow: '4px 4px 0px 0px #000',
            marginBottom: -20,
        },
        rowTextContainer: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
        starContainer: {
            position: 'relative',
        },
        starImageStyles: {
            position: 'absolute',
            top: -70
        },
        imageCardLine: {
            display: 'flex',
            flex: 1,
            transform: 'rotate(358deg)',
        },
        imageSize: {
            width: '95%',
            height: 'auto',
        },
        omgLogo: {
            width: 50,
            height: 'auto',
        },
        applyButton: {
            display: 'flex',
            position: 'relative',
            transition: 'top ease 0.5s',
            width: 133,
            height: 64,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            // borderRadius: 6,
            background: '#764FBE',
            boxShadow: '4px 4px 0px 0px #000',
            color: Colors.white,
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
            marginBottom: 48,
            cursor: 'pointer'
        },
        applyContainer: {
            display: 'flex',
            gap: 30
        },
    },
    laptop: {
        wrapper: {
            width: 'calc(100% - 345px)',
            display: 'flex',
            padding: '64px',
            backgroundImage: `url('${HomeBG}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            flexDirection: 'column',
            
        },
        wrapper2: {
            width: 'calc(100% - 345px)',
            display: 'flex',
            padding: '48px',
            backgroundImage: `url('${HomeBG}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            flexDirection: 'column',
        },
        firstFlexContainer: {
            display: 'flex',
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
        },
        textApplicationClosed: {
            lineHeight: 'normal'
        },
        textContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center'
        },
        biggerImage: {
            width: '80%'
        },
        biggerImage2: {
            width: '100%'
        },
        imgContainer: {
            position: 'relative'
        },
        trippleLinesContainer: {
            position: 'relative',
            display: 'flex',
        },
        taleasLogo: {
            width: 75,
            height: 'auto',
        },
        rowContainerTaleas: {
            display: 'flex',
            flexDirection: 'row',
        },
        logoSize: {
            width: 50,
            height: 'auto'
        },
        boldTextContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 10
        },
        textFixedWidth: {
            width: 450,
            marginTop: 24,
            marginBottom: 48,
            lineHeight: 1.5
        },
        textWidth: {
            width: 'fit-content',
        },
        coloredContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8
        },
        textColoredContainer: {
            fontSize: '48px'
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 'max-content',
            alignSelf: 'center',
            maxWidth: 650,
        },
        image: {
            width: '65%',
            height: '65%',
        },
        image2: {
            width: '100%',
            height: '65%',
        },
        cardContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
        },
        applicationInsideCardContainer: {
            display: 'flex',
            height: 60,
            padding: '16px',
            alignItems: 'center',
            flexShrink: 0,
            borderRadius: 6,
            border: '1px solid #000',
            background: Colors.white,
            boxShadow: '4px 4px 0px 0px #000',
            marginBottom: -20,
        },
        rowTextContainer: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
        starContainer: {
            position: 'relative',
        },
        starImageStyles: {
            position: 'absolute',
            top: -70
        },
        imageCardLine: {
            display: 'flex',
            flex: 1,
            transform: 'rotate(358deg)',
        },
        imageSize: {
            width: '95%',
            height: 'auto',
        },
        omgLogo: {
            width: 50,
            height: 137
        },
        applyButton: {
            display: 'flex',
            width: 133,
            height: 64,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            flexShrink: 0,
            // borderRadius: 6,
            background: '#764FBE',
            boxShadow: '4px 4px 0px 0px #000',
            color: Colors.white,
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
            marginBottom: 48,
            cursor: 'pointer'
        },
        applyContainer: {
            display: 'flex',
            gap: 10
        },

    },
    tablet: {
        wrapper: {
            display: 'flex',
            padding: '0 64px',
            flexDirection: 'column',
            backgroundImage: `url('${HomeBG}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            overflowX: 'hidden'
        },
        wrapper2: {
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url('${HomeBG}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: '0px 48px',
            overflowX: 'hidden'
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        textApplicationClosed: {
            lineHeight: 'normal'
        },
        topIconsRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '48px 0px',
        },
        taleasLogo: {
            width: 60,
            height: 'auto',
        },
        navbarOpener: {
            width: 42,
            height: 'auto',
        },
        freeLogo: {
            width: 34,
            height: 'auto',
            alignSelf: 'flex-end'
        },
        boldTextContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 16
        },
        coloredContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: 8
        },
        textWidth: {
            width: 'fit-content',
        },
        heightColored: {
            height: '38px',
            width: 'fit-content'
        },
        rowContainerTaleas: {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end'
        },
        textFixedWidth: {
            width:450,
            marginTop: 24,
            lineHeight: 1.5,
        },
        applyButton: {
            display: 'flex',
            width: 133,
            height: 48,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            flexShrink: 0,
            // borderRadius: 6,
            background: '#764FBE',
            boxShadow: '4px 4px 0px 0px #000',
            color: Colors.white,
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
        },
        applyContainer: {
            display: 'flex',
            flexDirection: 'row',
            margin: '48px 0px'
        },
        logoSize: {
            transform: 'rotate(45deg)',
            width: 33,
            height: 'auto',
            marginLeft: 8,
            marginTop: '-2%'
        },
        cardContainer: {
            display: 'flex',
            flexDirection: 'column-reverse',
            gap: 16,
            margin: '20px 0px'
        },
        applicationInsideCardContainer: {
            display: 'flex',
            width: 250,
            height: 60,
            padding: '16px',
            alignItems: 'center',
            flexShrink: 0,
            borderRadius: 6,
            border: '1px solid #000',
            background: Colors.white,
            boxShadow: '4px 4px 0px 0px #000',
            marginBottom: -20,
        },
        rowTextContainer: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
        omgLogo: {
            width: 37,
            height: 11,
            transform: 'rotate(-15deg)',
            paddingLeft: 16
        },
        starContainer: {
            position: 'relative',
        },
        starImageStyles: {
            position: 'absolute',
            top: -70
        },
        imageContainer: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 'max-content',
            alignSelf: 'center'
        },
        image: {
            width: '80%',
            height: 'auto',
        },
        textColoredContainer: {
            width: 'fit-content',
            textAlign: 'left'
        },
        imageCardLine: {
            display: 'flex',
            flex: 1,
        },
        imageSize: {
            width: 250,
            height: 'auto',
        },

    },
    mobile: {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url('${HomeBG}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: '0px 16px',
            marginBottom: 20,
            overflowX: 'hidden'
        },
        wrapper2: {
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url('${HomeBG}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: '0px 16px',
            marginBottom: 20,
            overflowX: 'hidden'
        },
        topIconsRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '24px 0px',
        },
        taleasLogo: {
            width: 60,
            height: 'auto',
        },
        navbarOpener: {
            width: 42,
            height: 'auto',
        },
        freeLogo: {
            width: 34,
            height: 'auto',
            alignSelf: 'flex-end'
        },
        textApplicationClosed: {
            lineHeight: 'normal'
        },
        boldTextContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        coloredContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: 8
        },
        textWidth: {
            width: 'fit-content',
        },
        heightColored: {
            height: '38px',
            width: 'fit-content'
        },
        rowContainerTaleas: {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end'
        },
        textFixedWidth: {
            width:'auto',
            marginTop: 24,
            marginBottom: 48,
            lineHeight: 1.5,
        },
        applyButton: {
            display: 'flex',
            width: 133,
            height: 48,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            flexShrink: 0,
            // borderRadius: 6,
            background: '#764FBE',
            boxShadow: '4px 4px 0px 0px #000',
            color: Colors.white,
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
        },
        applyContainer: {
            display: 'flex',
            flexDirection: 'row',
            margin: '48px 0px'
        },
        logoSize: {
            transform: 'rotate(45deg)',
            width: 33,
            height: 'auto',
            marginLeft: 8,
            marginTop: '-2%'
        },
        cardContainer: {
            display: 'flex',
            flexDirection: 'column-reverse',
            gap: 16,
            margin: '20px 0px',
            position: 'relative'
        },
        applicationInsideCardContainer: {
            display: 'flex',
            width: 250,
            height: 60,
            padding: '16px',
            alignItems: 'center',
            flexShrink: 0,
            borderRadius: 6,
            border: '1px solid #000',
            background: Colors.white,
            boxShadow: '4px 4px 0px 0px #000',
            marginBottom: -20,
        },
        rowTextContainer: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
        omgLogo: {
            width: 37,
            height: 11,
            transform: 'rotate(-15deg)',
            paddingLeft: 16
        },
        starContainer: {
            position: 'relative',
        },
        starImageStyles: {
            position: 'absolute',
            top: -70
        },
        imageContainer: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 'max-content',
            alignSelf: 'center',

        },
        image: {
            width: '110%',
            height: '110%',
        },
        textColoredContainer: {
            width: 'fit-content',
            textAlign: 'left',
        },
        imageCardLine: {
            display: 'flex',
            flex: 1,
        },
        imageSize: {
            width: '250px',
            height: 'auto',
        },
    }
}

export default HomeStyles