import React from 'react'
import T from "../assets/BW/TaleasBW6.svg"
import TColor from "../assets/Color/T.svg"
import A from "../assets/BW/TaleasBW5.svg"
import AColor from "../assets/Color/A.svg"
import L from "../assets/BW/TaleasBW1.svg"
import LColor from "../assets/Color/L.svg"
import E from "../assets/BW/TaleasBW2.svg"
import EColor from "../assets/Color/E.svg"
import A2 from "../assets/BW/TaleasBW3.svg"
import A2Color from "../assets/Color/A2.svg"
import S from "../assets/BW/TaleasBW4.svg"
import SColor from "../assets/Color/S.svg"
import "./ScoreVisualizer.css"
import { useGameManager } from '../context/GameManager'
export default function ScoreVisualizer() {
  const { letterScore } = useGameManager();
  return (
    <div className='score-row'>
      <span>Collected:</span>
        <img src={letterScore.includes("letterT") ? TColor : T } alt="" />
        <img src={letterScore.includes("letterA") ? AColor : A }  alt="" />
        <img src={letterScore.includes("letterL") ? LColor : L } alt="" />
        <img src={letterScore.includes("letterE") ? EColor : E } alt="" />
        <img src={letterScore.includes("letterA2") ? A2Color : A2 } alt="" />
        <img src={letterScore.includes("letterS") ? SColor : S } alt="" />
    </div>
  )
}
