import React, {useState, useMemo, useEffect} from 'react';
import {generateMaze} from '../utils/util.js';
import './Maze.css';
import {useGameManager} from '../context/GameManager.js';
import Questions from '../data/questions.json';
import QuestionModal from '../Components/QuestionModal.js';
import LeavingModal from '../Components/LeavingModal.js';
import useScreenSize from '../hooks/useScreenSize.js';
import ScoreVisualizer from '../Components/ScoreVisualizer.js';
import Stopwatch from '../Components/Stopwatch.js';
import Reset from '../assets/Icons/Reset.svg';
import Leave from '../assets/Icons/Leave.svg';

// eslint-disable-next-line react/prop-types
export default function Maze({questionIndex, pointOne}) {
  const size = 5;
  const [gameId, setGameId] = useState(1);
  const [status, setStatus] = useState('playing');
  const screenSize = useScreenSize();

  let sizeZ = screenSize.width <= 768 ? size + 2 : size + 2;
  let sizeY = screenSize.width <= 768 ? size + 2 : size + 2;

  // const [cheatMode, setCheatMode] = useState(false);
  const {appendToLetterScore, restartGame} = useGameManager();
  const [userPosition, setUserPosition] = useState([0, 0]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLeaveOpen, setIsLeaveOpen] = useState(false);
  const maze = useMemo(() => generateMaze(sizeZ, sizeY), [size, gameId]);
  // const solution = useMemo(() => {
  //   const s = new Set();
  //   const solutionPath = solve(maze, userPosition[0], userPosition[1]);
  //   solutionPath.forEach(path => {
  //     const [x, y] = path;
  //     s.add(String(x) + '-' + String(y));
  //   });
  //   return s;
  // }, [size, userPosition[0], userPosition[1], gameId]);

  useEffect(() => {
    console.log('Hello there');
    const lastRowIndex = maze.length - 1;
    const lastColIndex = maze[0].length - 1;
    if (
      userPosition[0] === lastRowIndex &&
      userPosition[1] === lastColIndex &&
      letterFound
    ) {
      setStatus('won');
      setIsModalOpen(true);
    }
  }, [userPosition[0], userPosition[1]]);

  useEffect(() => {
    console.log(status);
    console.log(isModalOpen);
  }, [status]);

  const [letterFound, setLetterFound] = useState(false);

  const [randomX, setRandomX] = useState(
    Math.floor(Math.random() * (size - 2)) + 2,
  );
  const [randomY, setRandomY] = useState(
    Math.floor(Math.random() * (size - 2)) + 2,
  );
  let tempx2, tempy2;

  do {
    tempx2 = Math.floor(Math.random() * (size - 2)) + 2;
  } while (tempx2 === randomX);

  do {
    tempy2 = Math.floor(Math.random() * (size - 2)) + 2;
  } while (tempy2 === randomY);

  const [collectedLetters, setCollectedLetters] = useState(0);
  useEffect(() => {
    if (
      !letterFound &&
      userPosition[0] === randomX &&
      userPosition[1] === randomY
    ) {
      console.log('E MORA SHKRONJEN E PARE');
      setLetterFound(true);
      appendToLetterScore(pointOne);
      setCollectedLetters(state => state + 1);
    }
  }, [userPosition, letterFound]);

  useEffect(() => {
    document.getElementById('maze-container').focus();
  });

  const makeClassName = (i, j) => {
    const rows = maze.length;
    const cols = maze[0].length;
    let arr = [];
    if (maze[i][j][0] === 0) {
      arr.push('topWall');
    }
    if (maze[i][j][1] === 0) {
      arr.push('rightWall');
    }
    if (maze[i][j][2] === 0) {
      arr.push('bottomWall');
    }
    if (maze[i][j][3] === 0) {
      arr.push('leftWall');
    }
    if (i === rows - 1 && j === cols - 1 && collectedLetters == 1) {
      arr.push('destination');
    }
    if (i === userPosition[0] && j === userPosition[1]) {
      arr.push('currentPosition');
    }
    if (!letterFound && i === randomX && j === randomY) {
      arr.push(pointOne);
    }

    // if (cheatMode && solution.has(String(i) + '-' + String(j))) {
    //   arr.push('sol');
    // }
    return arr.join(' ');
  };

  const handleMove = e => {
    e.preventDefault();
    if (status !== 'playing') {
      return;
    }
    const key = e.code;

    const [i, j] = userPosition;
    if ((key === 'ArrowUp' || key === 'KeyW') && maze[i][j][0] === 1) {
      setUserPosition([i - 1, j]);
    }
    if ((key === 'ArrowRight' || key === 'KeyD') && maze[i][j][1] === 1) {
      setUserPosition([i, j + 1]);
    }
    if ((key === 'ArrowDown' || key === 'KeyS') && maze[i][j][2] === 1) {
      setUserPosition([i + 1, j]);
    }
    if ((key === 'ArrowLeft' || key === 'KeyA') && maze[i][j][3] === 1) {
      setUserPosition([i, j - 1]);
    }
  };

  const moveUp = () => {
    if (status !== 'playing') {
      return;
    }
    const [i, j] = userPosition;
    if (maze[i][j][0] === 1) {
      setUserPosition([i - 1, j]);
    }
  };

  const moveDown = () => {
    if (status !== 'playing') {
      return;
    }
    const [i, j] = userPosition;
    if (maze[i][j][2] === 1) {
      setUserPosition([i + 1, j]);
    }
  };

  const moveLeft = () => {
    if (status !== 'playing') {
      return;
    }
    const [i, j] = userPosition;
    if (maze[i][j][3] === 1) {
      setUserPosition([i, j - 1]);
    }
  };

  const moveRight = () => {
    if (status !== 'playing') {
      return;
    }
    const [i, j] = userPosition;
    if (maze[i][j][1] === 1) {
      setUserPosition([i, j + 1]);
    }
  };

  const restartTheGame = () => {
    setUserPosition([0, 0]);
    setStatus('playing');
    setGameId(gameId + 1);
    restartGame();
    setLetterFound(false);
    setRandomX(Math.floor(Math.random() * (size - 2)) + 2);
    setRandomY(Math.floor(Math.random() * (size - 2)) + 2);

    console.log('Restarting...');
  };

  return (
    <>
      <div
        className="App-maze"
        onKeyDown={handleMove}
        tabIndex={-1}
        id="maze-container">
        <div className="maze-control-bar">
          {screenSize.width <= 768 ? (
            <div className="mobile-title">
              <div style={{fontSize: '32px', fontWeight: 'bold'}}>
                Taleas Maze
              </div>
              <ScoreVisualizer />
            </div>
          ) : (
            <div style={{fontSize: '32px', fontWeight: 'bold'}}>
              Taleas Maze
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
            }}>
            <Stopwatch />
            <button onClick={restartTheGame} className="restart-btn">
              <img src={Reset} alt="" />
            </button>
            {screenSize.width > 768 ? (
              <button
                className="leave-btn"
                onClick={() => setIsLeaveOpen(true)}>
                Leave game
              </button>
            ):  <button className="leave-btn" onClick={() => setIsLeaveOpen(true)}>
            <img src={Leave} alt="leave" />
          </button>}
          </div>
        </div>
        <table id="maze">
          <tbody>
            {maze.map((row, i) => (
              <tr key={`row-${i}`}>
                {row.map((cell, j) => (
                  <td key={`cell-${i}-${j}`} className={makeClassName(i, j)}>
                    <div />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <LeavingModal isOpen={isLeaveOpen} onClose={setIsLeaveOpen} />
        {status != 'playing' && (
          <QuestionModal question={Questions[questionIndex]} />
        )}
        {screenSize.width <= 768 && (
          <>
           
            <div className="control-buttons">
              <button onClick={moveUp} className="up-btn"></button>
              <div style={{display: 'flex', gap: '10px'}}>
                <button onClick={moveLeft} className="left-btn"></button>
                <button onClick={moveDown} className="down-btn"></button>
                <button onClick={moveRight} className="right-btn"></button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
