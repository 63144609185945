const Colors = {
  drawerBackground: '#222222',
  drawerText: '#FFFFFF',
  drawerIcon: '#FFFFFF',
  drawerActiveBackgroundRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  drawerScreenSeparator: '#F4F4F4',
  accent: '#E60000',
  accentRGBA: (alpha = 1) => `rgba(230, 0, 0, ${alpha})`,
  secondaryAccent: '#00B0CA',
  secondaryAccentRGBA: (alpha = 1) => `rgba(0, 176, 202, ${alpha})`,
  tertiaryAccent: '#00B0CA',
  background: '#222222',
  backgroundRGBA: (alpha = 1) => `rgba(34, 34, 34, ${alpha})`,
  qrBackGround: '#F4F4F4',
  qrMask: '#333333',
  borderColor: '#FFFFFF',
  secondaryBackround: '#2A2A2A',
  tertiaryBackground: '#333333',
  primaryText: '#FFFFFF',
  primaryTextRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  invertedText: '#FFFFFF',
  secondaryText: '#F8F8F8',
  tertiaryText: '#333333',
  quartenaryText: '#00B0CA',
  inActiveText: '#CCCCCC',
  white: '#ffffff',
  whiteRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  black: '#000000',
  blackRGBA: (alpha = 1) => `rgba(0, 0, 0, ${alpha})`,
  transparent: 'transparent',
  separator: '#666666',
  separatorBody: '#EBEBEB',
  error: '#EB9700',
  disabled: '#666666',
  tabAccent: '#FFFFFF',
  tabAccentRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  calendarAccent: '#00B0CA',
  scanLineAccent: '#00B0CA',
  scanEdgeAccent: '#FFFFFF',
  calendarAccentRGBA: (alpha = 1) => `rgba(0, 176, 202, ${alpha})`,
  calendarText: '#FFFFFF',
  // Icons
  primaryIcon: '#FFFFFF',
  primaryIconRGBA: (alpha = 0) => `rgba(255, 255, 255, ${alpha})`,
  secondaryIcon: '#FFFFFF',
  tertiaryIcon: '#FFFFFF',
  quartenaryIcon: '#333333',
  successForm: '#529B55',
  errorForm: '#EE6666',
  // Button
  buttonTextAccent: '#E60000',
  buttonSecondaryAccent: '#FFFFFF',
  buttonTertiaryAccent: '#666666',
  purpleC: '#764FBE',
  lightGreyC: '#7C7C7C',
  orangeP: '#F97171',
  greyText: '#616161',
  blueLight: '#85D7E2',
  //Switch
  activeSwitch: 'rgba(0, 124, 146, 1)',
  inactiveSwitch: 'rgba(153, 153, 153, 1)',
  //Grey
  lightGrey: '#ECECEC',
  midGrey: '#ACACAC',
  placeholder: '#ACACAC',
  //yellow: '#FCD716',
  //orange: '#FBAC5B',
  pink: '#F15C9A',
  tomato: '#FF623E',
  sky: '#6CCDFE',
  //green: '#71BE35',

  //Color Picker
  orange: '#EB9700',
  purple: '#9C2AA0',
  green: '#009900',
  yellow: '#FECB00',
  red: '#E60000',
  blue: '#007C92',
  pending: '#333333',
  gray: '#333333',

  turquoise: '#06D4D4',
  turquoiseRGBA: (alpha = 1) => `rgba(6, 212, 212, ${alpha})`,
}

export default Colors