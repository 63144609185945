import Colors from "../../utils/colors/Colors"


const TextAreaStyles = {
  desktop: {
    layout: {
      display: 'flex',
      height: 128,
      width: 662,
      padding: '8px 16px',
      alignItems: 'flex-start',
      gap: 10,
      alignSelf: 'stretch',
      borderRadius: 6,
      border: '1px solid #000',
      background: Colors.white,
      resize: 'none',
      /* neobrutalisem shadow */
      boxShadow: '4px 4px 0px 0px #000'
    }
  },
  mobile: {
    layout: {
      display: 'flex',
      height: 128,
      width: '-webkit-fill-available',
      padding: '8px 16px',
      alignItems: 'flex-start',
      gap: 10,
      alignSelf: 'stretch',
      borderRadius: 6,
      border: '1px solid #000',
      background: Colors.white,
      resize: 'none',
      /* neobrutalisem shadow */
      boxShadow: '4px 4px 0px 0px #000'
    }
  }
}

export default TextAreaStyles