import React from 'react'
import PropTypes from 'prop-types'
import InputFieldStyles from './InputField.Styles'
import './inputField.css'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const InputField = (props) => {
  const Dimension = useWindowDimensions()
  const styles = Dimension > 1023 ? { ...InputFieldStyles.desktop } : { ...InputFieldStyles.mobile }


  const {
    placeHolder,
    onChange,
    onBlur,
    value,
    style,
  } = props


  const allStyles = Object.assign({},
    styles.layout
    , style
  );

  return (
    <input type='text' placeholder={placeHolder} style={allStyles} onChange={onChange} value={value} className='removeOutline' onBlur={onBlur} />
  )
}

InputField.propTypes = {
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}

export default React.memo(InputField)