import Colors from "../../utils/colors/Colors";

const NavbarStyles = {
    desktop: {
        container: {
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            border: '4px solid #000',
            height: 'calc(100% - 8px)',
            flex: 1,
            background: Colors.white,
            overflow: 'scroll',
            zIndex: 10
        },
        containerRoutingStyles: {
            padding: '24px',
            gap: 32,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            borderBottom: '4px solid #000',
            alignItems:"center",
            justifyContent: 'center'
        },
        ContactUsNavStyle: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // justifyContent: 'center',
            gap: 38,
            paddingTop: 32,
            flex: 1,

        },
        taleasLogo: {
            width: 75,
            height: 'auto',
            alignSelf: 'center',
            cursor: 'pointer'
        },
        img: {
            width: 32,
            height: 'auto'
        },
        socialsContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 32,
            margin: '0px',
            cursor: 'pointer',
            
        },
        textSize: {
            fontWeight: 700,
        },
        NavbarYellowWave: {
            width: 255,
        },
        tegeriaContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        },
        tegeriaLogo: {
            width: 56,
        },
        textTegeria: {
            width: 'auto'
        }
    },
    laptop: {
        container: {
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            border: '4px solid #000',
            height: 'calc(100% - 8px)',
            flex: 1,
            background: Colors.white,
            overflow: 'scroll',
            zIndex: 10
        },
        containerRoutingStyles: {
            padding: '24px',
            gap: 32,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            borderBottom: '4px solid #000',
            justifyContent: 'center'
        },
        ContactUsNavStyle: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 38,
            flex: 1,
            paddingTop: 32

        },
        taleasLogo: {
            width: 75,
            height: 'auto',
            alignSelf: 'center',
            cursor: 'pointer'
        },
        img: {
            width: 32,
            height: 'auto'
        },
        socialsContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 32,
            cursor: 'pointer'
        },
        textSize: {
            fontWeight: 700,
        },
        NavbarYellowWave: {
            width: 255,
        },
        tegeriaContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        },
        tegeriaLogo: {
            width: 56,
        },
        textTegeria: {
            width: 'auto'
        }

    },
    tablet: {
        container: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            zIndex: 10
        },
        containerRoutingStyles: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 32,
            padding: '32px',
            borderBottom: '4px solid #000',
            width: '-webkit-fill-available',
            marginBottom: '42px'
        },
        ContactUsNavStyle: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 32,
            width: '100%',
            alignItems: 'center'
        },
        img: {
            width: 32,
            height: 'auto',
        },
        imgP: {
            width: 50,
            height: 50,
            display: 'flex',
            alignSelf: 'center'
        },
        socialsContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 72,
        },
        lightBulb: {
            display: 'flex',
            width:60,
            height: 60,
        },
        closeIcon: {
            margin: '0px',
            width: 42,
            height: 42,
        },
        NavbarYellowWave: {
            width: '100%',
        },
        textSize: {
            fontSize: '22px',
            fontWeight: 700,
        },
        navHeaderIconContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 'calc(100% - 32px)',
            alignItems:'center',
            padding:'24px 0px'
        },
        tegeriaContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            justifyContent: 'center',
            alignItems: 'center',
            margin: '8px 0px',

        },
        tegeriaLogo: {
            width: 56,
        },
        textTegeria: {
            width: 'auto'
        }

    },
    mobile: {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            flex:1,
            height:'100%',
            zIndex: 10
        },
        containerRoutingStyles: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 32,
            padding: '32px',
            borderBottom: '4px solid #000',
            width: 'calc(100% - 50px)',
            marginBottom: '42px'
        },
        ContactUsNavStyle: {
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
        },
        img: {
            width: 32,
            height: 'auto',
        },
        imgP: {
            width: 50,
            height: 50,
            display: 'flex',
            alignSelf: 'center'
        },
        socialsContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            justifyContent: 'center'
        },
        lightBulb: {
            display: 'flex',
            width: 60,
            height: 60,
        },
        closeIcon: {
            margin: '0px',
            width: 42,
            height: 42,
        },
        NavbarYellowWave: {
            width: '100%',
            alignSelf:'flex-end'
        },
        textSize: {
            fontSize: '22px',
            fontWeight: 700,
        },
        navHeaderIconContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 'calc(100% - 32px)',
            alignItems:'center',
            padding:'24px 0px'
        },
        tegeriaContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            justifyContent: 'center',
            alignItems: 'center',
            margin: '8px 0px'
        },
        tegeriaLogo: {
            width: 56,
            // height: '20px'
        },
        textTegeria: {
            width: 'auto'
        },
        imageContainer: {
            display: 'flex',
            width: '100%',
            height: '100%'
        }
    },
}

export default NavbarStyles