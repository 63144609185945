import React, { useRef, useState } from 'react'
import CardComponentStyles from './CardComponent.Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import _ from 'lodash'
import Text from '../Text/Text'
import Wow from '../../utils/images/Wow.svg'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from "framer-motion";

const CardComponent = props => {
  const Dimension = useWindowDimensions()

  const cardStyles = Dimension.width > 1440 ? { ...CardComponentStyles.card.desktop } : Dimension.width > 1023 && Dimension.height < 1440 ?
    { ...CardComponentStyles.card.laptop } : { ...CardComponentStyles.card.mobile }

  const cardProgramStyles = Dimension.width > 1440 ? { ...CardComponentStyles.cardProgram.desktop } : Dimension.width > 1023 && Dimension.height < 1440 ?
    { ...CardComponentStyles.cardProgram.laptop } : Dimension.width > 767 && Dimension.width < 1024 ? { ...CardComponentStyles.cardProgram.tablet } : { ...CardComponentStyles.cardProgram.mobile }

  const {
    cardData,//text,image,isFilled
    cardProgramData,
    style,
  } = props

  const translateAmount2 = (Dimension.width - 64)/ 2
  const translateAmount = (Dimension.width - 64) / 2.3
  const translateAmount3 = (Dimension.width - 64) / 1.15
  const translateAmount4 = (Dimension.width - 64) / 1.65

  const ref = useRef()

  const [selectedProgram, setSelectedProgram] = useState(cardData[0])

  const scroll = (scrollOffset, id) => {
    if (selectedProgram.id === id) {
      return ;
    }
    if (selectedProgram.id > id) {
      ref.current.scrollLeft -= scrollOffset;
    }
    if (selectedProgram.id < id) {
      ref.current.scrollLeft += scrollOffset;
    }
  };

  const handleSelected = (item) => {
    if (!_.isEqual(item, selectedProgram)) {
      setSelectedProgram(item)
      if (Dimension.width > 600 && Dimension.width < 707) {
        scroll(translateAmount, item.id )
      }
      if (Dimension.width < 600) {
        scroll(translateAmount, item.id )
      }
      if (Dimension.width < 400) {
        scroll(translateAmount2, item.id )
      }
      if (Dimension.width < 380) {
        scroll(translateAmount4, item.id )
      }
      if (Dimension.width < 340) {
        scroll(translateAmount3, item.id )
      }
    }
  }

  const index2 = cardData.indexOf(selectedProgram)
  const newCardProgramData = [cardProgramData[index2]]


  return (
    <>
      {Dimension.width < 1024 ? <div style={{ marginTop: 32 }}>

        <div ref={ref} style={cardStyles.container} className='removeScrollbar'>
          {
            cardData.map((item) => {
              const allStyles = Object.assign({},
                cardStyles.defaultStyles,
                selectedProgram === item ? { background: item.color, color: '#fff' } : null,
                style
              );

              return (
                <div id='div222'  key={item.id} style={allStyles} onClick={() => handleSelected(item, item.id, selectedProgram)}>
                  <img src={item.shadowImage} alt='' style={cardStyles.shadowImage} />
                  <img src={item.image} alt='' style={cardStyles.image} />
                  <Text text={item.text} color={item === selectedProgram ? '#fff' : item.color} style={cardStyles.text} />
                </div>
              )
            })
          }
        </div>
      </div> :
        <div style={Dimension.width > 1060 ? cardStyles.container : cardStyles.container2} className='removeScrollbar'>
          {
            cardData.map((item, index) => {
              const allStyles = Object.assign({},
                cardStyles.defaultStyles,
                selectedProgram === item ? { background: item.color, color: '#fff' } : null,
                style
              );

              return (
                <div key={index} style={allStyles} onClick={() => handleSelected(item, selectedProgram)}>
                  <img src={item.shadowImage} alt='' style={cardStyles.shadowImage} />
                  <img src={item.image} alt='' style={cardStyles.image} />
                  <Text text={item.text} color={item === selectedProgram ? '#fff' : item.color} style={cardStyles.text} />
                </div>
              )
            })
          }
        </div>
      }
      {Dimension.width > 1023 ? <div style={cardProgramStyles.trippleLinesContainer}>
        <img src={Wow} alt='' style={cardProgramStyles.WowLogo} />
      </div> : null}
      <div style={cardProgramStyles.TopCardProgramContainer}>
        {Dimension.width < 1024 ? <div style={cardProgramStyles.trippleLinesContainer}>
          <img src={Wow} alt='' style={cardProgramStyles.WowLogo} />
        </div> : null}
        <div style={cardProgramStyles.CardProgramContainer}>
          {
            newCardProgramData?.map((item, index) => {
              const allStyles = Object.assign({},
                cardProgramStyles.defaultStyles,
              );
              return (
                <AnimatePresence mode='wait' key={index}>
                  <motion.div
                    key={index2}
                    initial={{ x: 10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    style={allStyles}>
                    <img src={item?.image} alt='' style={cardProgramStyles.image} />
                    <div style={index2 !== 2 ? cardProgramStyles.textContentStyles : cardProgramStyles.textContentStyles2}>
                      <Text text={item?.text} textWeight={'bold'} size={Dimension.width < 1022 ? 'h5' : 'body'} align={'left'} />

                      {
                        item?.secondDescription !== '' ?
                          <div style={cardProgramStyles.descriptionContainer}>
                            <Text text={item?.description} size={'note'} align={'left'} style={cardProgramStyles.description} />
                            <Text text={item?.secondDescription} size={'note'} align={'left'} style={cardProgramStyles.description} />
                          </div> :
                          <Text text={item?.description} size={'note'} align={'left'} style={cardProgramStyles.description} />
                      }
                      {item?.secondaryTitle !== '' ? <Text text={item?.secondaryTitle} textWeight={'bold'} size={Dimension.width < 1022 ? 'h5' : 'body'} align={'left'} /> : null}
                      {item?.bulletList.length > 0 ?
                        <ul style={cardProgramStyles.bulletListContainer}>
                          {
                            item?.bulletList.map((item, index) => {
                              return (
                                <li key={index} style={index2 === 1 ? cardProgramStyles.bulletListIconOrange : cardProgramStyles.bulletListIcon}>
                                  {item?.text}
                                </li>
                              )
                            })
                          }
                        </ul> : null
                      }
                    </div>
                  </motion.div>
                </AnimatePresence>
              )
            })
          }

        </div >
      </div >
    </>
  )
}

CardComponent.propTypes = {
  cardData: PropTypes.array,
  cardProgramData: PropTypes.array,
  index: PropTypes.number,
  translate: PropTypes.number,
  setTranslate: PropTypes.func,
  style: PropTypes.oneOf([
    PropTypes.array,
    PropTypes.object
  ])
}

export default CardComponent