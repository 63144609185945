import React, { useState } from 'react'
import Text from '../../components/Text/Text'
import TestImage from '../../utils/images/fluent_window-dev-edit-20-regular.svg'
// import computerOurProgram from '../../utils/images/computerOurProgram.png'
import OurProgramStyles from './OurProgram.Styles'
import TaleasLogo from '../../utils/images/TaleasLogo.svg'
import PurpleShadow from '../../utils/images/PurpleShadow.svg'
import RedShadow from '../../utils/images/RedShadow.svg'
import BlueShadow from '../../utils/images/BlueShadow.svg'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import navbarOpener from '../../utils/images/navbarOpener.svg'
import blueOurProgram from '../../utils/images/blueOurProgram.svg'
import redOurProgramIcon from '../../utils/images/redOurProgramIcon.svg'
import training from '../../utils/images/img1.svg'
import tech from '../../utils/images/tech.png'
import takeway from '../../utils/images/img3.svg'

import CardComponent from '../../components/CardComponent/CardComponent'
import { useNavigate } from 'react-router-dom'

//COLORS
const CardData = [{
  text: 'Sessions', image: TestImage, shadowImage: PurpleShadow, color: '#7750BF',id: 1
}, {
  text: 'Technologies', image: redOurProgramIcon, shadowImage: RedShadow, color: '#F97171', id: 2
}, {
  text: 'Take-away', image: blueOurProgram, shadowImage: BlueShadow, color: '#85D7E2',id: 3
}]
//Format
const CardProgramData = [
  {
    text: 'About the training', image: training, description: 'Taleas is a free up-skilling tech-only programme. Intent-driven education - learn by doing and setting clear goals.\nProgram lasts from 2 to 3 months.\nAt least 2 a year.',
    secondDescription: '',
    secondaryTitle: 'The outcome',
    bulletList: [
      { text: 'Functional MVP' }
      , { text: 'Hands-on experience' }
      , { text: 'Professional self-awareness' }
      , { text: 'Job opportunity' }
      , { text: 'And more' }
    ]
  },
  {
    text: 'Software Engineering', image: tech, description: 'Software engineering refers to the systematic design, development, testing, and maintenance of software applications and systems.',
    secondDescription: '',
    secondaryTitle: 'Trainings',
    bulletList: [
      { text: 'Font-end engineering' }
      , { text: 'Cloud engineering' }
      , { text: 'Back-end engineering' }
      , { text: 'DevOps & Automation' }
      , { text: 'Game Development & 3D Modeling' }
    ]
  },
  {
    text: 'Take-away', image: takeway,
    description: 'Upon program completion, participants have the opportunity to present their self-conceived project. This exercise allows them to engage in group collaboration, simulating real-world work dynamics and experiences.',
    secondDescription: 'Apart from acquiring valuable hard skills, participants also develop essential soft skills by the programs conclusion. These include presentation abilities, effective time management, accurate task estimation, and more.',
    secondaryTitle: '',
    bulletList: []

  },
]

//eslint add props

// eslint-disable-next-line react/prop-types
const OurProgram = ({ showNavbar, setShowNavbar }) => {
  const Dimension = useWindowDimensions()
  const navigate = useNavigate()
  const [translate, setTranslate] = useState(0)

  const styles = Dimension.width > 1440 ? { ...OurProgramStyles.desktop } : Dimension.width > 1023 && Dimension.width < 1441 ?
    { ...OurProgramStyles.laptop } : Dimension.width > 767 && Dimension.width < 1024 ? { ...OurProgramStyles.tablet } : { ...OurProgramStyles.mobile }


  const handleRoutingHome = () => {
    navigate('/')
    setShowNavbar(false)
  }


  return (
    <>
      {Dimension.width < 1024 && showNavbar ? null : <div style={Dimension.height < 800 ? styles.wrapper2 : styles.wrapper} className='wrapper'>
        {Dimension.width < 1024 ? <div style={styles.topIconsRow}>
          <img src={TaleasLogo} alt='a logo that identifies taleas' style={styles.taleasLogo} onClick={handleRoutingHome} />
          <img src={navbarOpener} alt='an icon to help with the functionality of opening navbar' style={styles.freeLogo} onClick={() => setShowNavbar((true))} />
        </div> : null}
        {Dimension.width > 1023 ?
          <div style={styles.header}>
            <Text text={'Our Programs'} size={'h3'} style={{ fontWeight: 700 }} />
          </div> : <Text text={'Our Programs'} size={'h3'} style={{ fontWeight: 700 }} />

        }
        <CardComponent cardData={CardData} cardProgramData={CardProgramData} translate={translate} setTranslate={setTranslate} style={styles.horizontalContainer} />
      </div>}
    </>

  )
}

export default OurProgram