import { isFunction as _isFunction, get as _get, isNil as _isNil } from 'lodash'
import { InvalidParameterException } from './Validate'


function constructUtilities(utilities = {}, customUtilities = {}) {
    const _utilities = Object.assign(
        {},
        {
            onStart: () => { },
            onSuccess: () => { },
            onError: undefined,
            responseMapper: response => _get(response, 'data', response),
            errorMapper: error => _get(error, 'response', error),
        },
        customUtilities,
        utilities
    )

    return _utilities
}

function validateUtilities(utilities = {}) {
    const { errorMapper, onError, onStart, onSuccess, responseMapper } = utilities

    if (
        !_isFunction(errorMapper) ||
        (!_isNil(onError) && !_isFunction(onError)) ||
        !_isFunction(onStart) ||
        !_isFunction(onSuccess) ||
        !_isFunction(responseMapper)
    ) {
        throw new InvalidParameterException(
            'Provided utilities parameter contains invalid value',
            'onStart, onSuccess, onError, responseMapper, errorMapper',
            'undefined || function'
        )
    }

    return true
}

function retrieveUtilities(utilities = {}, customUtilities = {}) {
    const _utilities = constructUtilities(utilities, customUtilities)
    validateUtilities(_utilities)

    return _utilities
}

export { retrieveUtilities }