import React, { useEffect, useState } from 'react';
import { useGameManager } from '../context/GameManager';
import Maze from './Maze';
import ScoreVisualizer from '../Components/ScoreVisualizer';
import './GameScene.css';
import WASD from '../assets/Images/WASD.svg';
import GameFinished from './GameFinished';
import Progressbar from '../Components/ProgressBar';
import useScreenSize from '../hooks/useScreenSize';
export default function GameScene() {
  const screenSize = useScreenSize();
  const [isloaded, setIsLoaded] = useState(false);
  const { gameState } = useGameManager();
  let MazeLevel;
  const [randomIndex, setRandomIndex] = useState([]);
  useEffect(() => {
    // Scroll to the top of the window when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    function generateRandomNumbers(min, max, count) {
      let numbers = [];

      while (numbers.length < count) {
        let random = Math.floor(Math.random() * (max - min) + min);
        if (!numbers.includes(random)) {
          numbers.push(random);
        }
      }
      setRandomIndex(numbers);
    }
    generateRandomNumbers(0, 17, 6);
  }, []);

  // useEffect(()=>{
  //   setIsLoaded(false)
  // },[gameState])

  switch (gameState) {
    case 0:
      MazeLevel = (
        <Maze
          pointOne={'letterT'}
          key="level1"
          questionIndex={randomIndex[0]}
        />
      );
      break;
    case 1:
      MazeLevel = (
        <Maze
          pointOne={'letterA'}
          key="level2"
          questionIndex={randomIndex[1]}
        />
      );
      break;
    case 2:
      MazeLevel = (
        <Maze
          pointOne={'letterL'}
          key="level3"
          questionIndex={randomIndex[2]}
        />
      );
      break;
    case 3:
      MazeLevel = (
        <Maze
          pointOne={'letterE'}
          key="level4"
          questionIndex={randomIndex[3]}
        />
      );
      break;
    case 4:
      MazeLevel = (
        <Maze
          pointOne={'letterA2'}
          key="level3"
          questionIndex={randomIndex[4]}
        />
      );
      break;
    case 5:
      MazeLevel = (
        <Maze
          pointOne={'letterS'}
          key="level5"
          questionIndex={randomIndex[5]}
        />
      );
      break;

    default:
      MazeLevel = <GameFinished />;
      break;
  }

  if (gameState > 5) {
    return <GameFinished />;
  }

  if (!isloaded) {
    return <Progressbar setIsLoaded={setIsLoaded} />;
  }

  return (
    <div className="game-scene">
      <div className="maze-container">{MazeLevel}</div>
      <div className="game-footer">
        {screenSize.width > 768 && (
          <div className="game-instructions">
            <span>To move </span>
            <img src={WASD} style={{ width: '133px' }} alt="" />
          </div>
        )}

        {screenSize.width > 768 && <ScoreVisualizer />}
      </div>
    </div>
  );
}
