import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import "./QuestionModal.css";
import RoboKeisi from "../assets/RoboKeisi/RobotAsking.svg";
import Happy from "../assets/RoboKeisi/WinkRobot.svg";
import Sad from "../assets/RoboKeisi/SadRobot.svg";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useGameManager } from "../context/GameManager";
import { useNavigate } from "react-router-dom";

const QuestionModal = ({ question }) => {
  const { setGameState, startStop, setScore, gameState } = useGameManager();
  const [hasAnswered, setHasAnswered] = useState(null);
  const [isCorrectAswer, setIsCorrectAswer] = useState("");
  const navigate = useNavigate();
  let Keisi = RoboKeisi;

  if (hasAnswered && isCorrectAswer === "correct") {
    Keisi = Happy;
  } else if (hasAnswered && isCorrectAswer === "wrong") {
    Keisi = Sad;
  } else {
    Keisi = RoboKeisi;
  }

  const handleAnswer = (isCorrect) => {
    setHasAnswered(true);
    startStop();
    setIsCorrectAswer(isCorrect ? "correct" : "wrong");
    if (isCorrect) {
      setScore((score) => score + 1);
    }
    setTimeout(() => {
      if (gameState + 1 === 6) {
        navigate("/contact");
      } else {
        setGameState((state) => state + 1);
      }
      startStop();
    }, 3000);
  };

  return (
    <>
      <div className={`modal-overlay roadRunnerIn`}>
        <div className={`modal ${isCorrectAswer}`}>
          <div className="modal-title">
            <span>Solve this quiz</span>
          </div>
          <div className="modal-question">{question.question}</div>
          <div
            style={{
              overflow: "auto",
              whiteSpace: "nowrap",
              textAlign: "left",
            }}
            className="modal-code"
          >
            {question.code_snippet !== '' &&
              <SyntaxHighlighter  style={docco}>
                {question.code_snippet}
              </SyntaxHighlighter>
            }
          </div>
          <div className="modal-answer">
            <img src={Keisi} alt="" className="keisi-bot" />
            <div className="answer-buttons">
              {question.answers.map((q, index) => (
                <button
                  disabled={hasAnswered}
                  key={index}
                  className={`answer-btn ${hasAnswered && q.isCorrect
                      ? "correct-answer"
                      : hasAnswered && !q.isCorrect
                        ? "wrong-answer"
                        : ""
                    }`}
                  onClick={() => handleAnswer(q.isCorrect)}
                >
                  {q.answer}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Add PropTypes for question prop
QuestionModal.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    code_snippet: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string.isRequired,
        isCorrect: PropTypes.bool.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default QuestionModal;
