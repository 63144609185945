import React, { createContext, useState, useContext } from "react";

const GameManagerContext = createContext();

export function useGameManager() {
  return useContext(GameManagerContext);
}

// eslint-disable-next-line react/prop-types
export default function GameManageProvider({ children }) {
  const [score, setScore] = useState(0);
  const [time, setTime] = useState(0);
  const [gameState, setGameState] = useState(0);
  const [letterScore,setLetterScore] = useState([])
  const [isTimerRunning,setIsTimerRunning] = useState(true)
  const appendToLetterScore = (newElement) => {
    setLetterScore(prevLetterScore => [...prevLetterScore, newElement]);
  };
  const clearScore = () => {
    setScore(0);
  };

  const startStop = () => {
    setIsTimerRunning((prevState) => !prevState);
  };
  const restartGame = () =>{
    setGameState(0)
    setLetterScore([])
    setScore(0)
    setTime(0)
  
  }
  return (
    <GameManagerContext.Provider
      value={{ score, setScore, clearScore, gameState, setGameState,letterScore,appendToLetterScore,restartGame ,time, setTime,isTimerRunning,setIsTimerRunning,startStop}}
    >
      {children}
    </GameManagerContext.Provider>
  );
}
