import Colors from "../../utils/colors/Colors"

const UploadFileStyles = {
    desktop: {
        uploadPhotoContainer: {
            display: 'flex',
            background: Colors.white,
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            border: '1px solid #000',
            alignItems: 'center',
            padding: '10px',
            borderRadius: '5px',
            gap: '5px',
            position: 'relative',
            boxShadow: '4px 4px 0px 0px #000',
        },
        uploadPhotoContainerChild: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            width: '100%',
            alignItems: 'center',
            gap: 32
        },
        uploadedImage: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            marginLeft: '13.5px',
            gap: '17.5px',
            alignItems: 'center',
        },
        cancelUpload: {
            marginRight: '11px',
        },
        text: {
            textDecoration: 'none',
            color: '#7851BF',
            width: 'max-content',
            fontFamily: 'Open Sans',
        },
        uploadImageContainer: {
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Open Sans',
            width: '-webkit-fill-available',
            height: 34,
            padding: '16px',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 16,
            border: '1px dashed #764FBE',
            background: Colors.white,
            cursor: 'pointer',
            color: Colors.purpleC,
            borderRadius: 6,
            zIndex: -10
        },
        uploadImageContainerLowerPadding: {
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Open Sans',
            width: '-webkit-fill-available',
            height: 34,
            padding: '16px',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 16,
            border: '1px dashed #764FBE',
            background: Colors.white,
            cursor: 'pointer',
            color: Colors.purpleC,
            borderRadius: 6,
            zIndex: -10
        },
        image: {
            width: 20,
            height: 'auto'
        },
        inputImage: {
            display: 'none',
        },
        uploadImageTitle: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        fixAlignmnet: {
            alignItems: 'center',
        },
        bigContainerUploadImage: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: 5,
            width: '60%',
        },

        iconPosition: {
            position: 'relative',
        },
        tooltipContent: {
            width: 200,
            height: 200,
            backgroundColor: 'black',
            color: '#fff',
            textAlign: 'center',
            borderRadius: 6,
            padding: '5px 0px',
            bottom: '100%',
            left: '50%',
            marginLeft: '-60px',

            /* Position the tooltip */
            zIndex: 1,
            position: 'absolute',
        },
        dragNdrop: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '1rem',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        textContainer: {
            display: 'flex',
            gap: 4
        }
    },
    laptop: {
        uploadPhotoContainer: {
            display: 'flex',
            background: Colors.white,
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            border: '1px solid #000',
            alignItems: 'center',
            padding: '10px',
            borderRadius: '5px',

            gap: '5px',
            position: 'relative',
            boxShadow: '4px 4px 0px 0px #000',
            width: '82%'
        },
        uploadPhotoContainerChild: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            width: '100%',
            alignItems: 'center',
            gap: 32
        },
        uploadedImage: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            marginLeft: '13.5px',
            gap: '17.5px',
            alignItems: 'center',
        },
        cancelUpload: {
            marginRight: '11px',
        },
        text: {
            textDecoration: 'none',
            color: '#7851BF',
            width: 'max-content',
            fontFamily: 'Open Sans',
        },
        uploadImageContainer: {
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Open Sans',
            width: '80%',
            height: 34,
            padding: '16px',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 16,
            border: '1px dashed #764FBE',
            background: Colors.white,
            cursor: 'pointer',
            color: Colors.purpleC,
            borderRadius: 6,
            zIndex: -10,
        },
        uploadImageContainerLowerPadding: {
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Open Sans',
            width: '80%',
            height: 34,
            padding: '16px',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 16,
            border: '1px dashed #764FBE',
            background: Colors.white,
            cursor: 'pointer',
            color: Colors.purpleC,
            borderRadius: 6,
            zIndex: -10
        },
        image: {
            width: 20,
            height: 'auto'
        },
        inputImage: {
            display: 'none',
        },
        uploadImageTitle: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        fixAlignmnet: {
            alignItems: 'center',
        },
        bigContainerUploadImage: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: 5,
            width: '100%',
        },

        iconPosition: {
            position: 'relative',
        },
        tooltipContent: {
            width: 200,
            height: 200,
            backgroundColor: 'black',
            color: '#fff',
            textAlign: 'center',
            borderRadius: 6,
            padding: '5px 0px',
            bottom: '100%',
            left: '50%',
            marginLeft: '-60px',

            /* Position the tooltip */
            zIndex: 1,
            position: 'absolute',
        },
        dragNdrop: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '1rem',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        textContainer: {
            display: 'flex',
            gap: 4
        }
    },
    mobile: {
        uploadPhotoContainer: {
            display: 'flex',
            background: Colors.white,
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            border: '1px solid #000',
            alignItems: 'center',
            padding: '10px',
            borderRadius: '5px',
            width: 'auto',
            gap: '5px',
            position: 'relative',
            boxShadow: '4px 4px 0px 0px #000',
        },
        uploadPhotoContainerChild: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            width: '100%',
            alignItems: 'center',
            gap: 32
        },
        uploadedImage: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            marginLeft: '13.5px',
            gap: '17.5px',
            alignItems: 'center',
        },
        cancelUpload: {
            marginRight: '11px',
        },
        text: {
            textDecoration: 'none',
            color: '#7851BF',
            width: 'max-content',
            fontFamily: 'Open Sans',
        },
        uploadImageContainer: {
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Open Sans',
            width: 'auto',
            height: 34,
            padding: '17px 12px',
            paddingRight: 72,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 16,
            border: '1px dashed #764FBE',
            background: Colors.white,
            cursor: 'pointer',
            color: Colors.purpleC,
            borderRadius: 6,
            zIndex: -10,
        },
        uploadImageContainerLowerPadding: {
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Open Sans',
            width: 'auto',
            height: 34,
            padding: '17px 8px',
            paddingRight: 15,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 16,
            border: '1px dashed #764FBE',
            background: Colors.white,
            cursor: 'pointer',
            color: Colors.purpleC,
            borderRadius: 6,
            zIndex: -10
        },
        image: {
            width: 20,
            height: 'auto'
        },
        inputImage: {
            display: 'none',
        },
        uploadImageTitle: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        fixAlignmnet: {
            alignItems: 'center',
        },
        bigContainerUploadImage: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: 5,
            width: '-webkit-fill-available',

        },

        iconPosition: {
            position: 'relative',
        },
        tooltipContent: {
            width: 200,
            height: 200,
            backgroundColor: 'black',
            color: '#fff',
            textAlign: 'center',
            borderRadius: 6,
            padding: '5px 0px',
            bottom: '100%',
            left: '50%',
            marginLeft: '-60px',

            /* Position the tooltip */
            zIndex: 1,
            position: 'absolute',
        },
        dragNdrop: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '1rem',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        textContainer: {
            display: 'flex',
            gap: 4,
            flexDirection:'column'
        }
    }
}

export default UploadFileStyles