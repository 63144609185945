import BackgroundPhoto from '../../utils/images/Background.svg'

const AboutStyles = {
    desktop: {
        wrapper: {
            width: 'calc(100% - 455px)',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            display: 'flex',
            height: '100%',
            overflow: 'scroll',
            justifyContent: 'center',
            padding: '100px 100px 32px 100px',
        },
        wrapper2: {
            height: 'fit-content',
            width: 'calc(100% - 455px)',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            display: 'flex',
            overflow: 'scroll',
            justifyContent: 'center',
            padding: '100px 100px 32px 100px',
        },
        bigContainerBigScreenHandle: {
            display: 'flex',
            maxWidth: "1700px"
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 64,
        },
        PurpleAboutLogo: {
            width: '288px',
            height: '268px',
            flexShrink: 0,
            position: 'absolute',
            top: '-5%',
            right: '40%',
        },
        blueAboutLogo: {
            width: '246px',
            height: '229px',
            transform: 'rotate(351deg)',
            flexShrink: 0,
            position: 'absolute',
            top: '70%',
            left: '-6%',
        },
        containerAboutTaleas: {
            display: 'flex',
            flexDirection: 'column',
            gap: 24
        },
        ElephantLineLogo: {
            width: '714px',
            height: '711px',
            flexShrink: 0,
            position: 'absolute',
            bottom: '0%',
            left: '-7%',
            zIndex: -54,
        },
        innerContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 64,
        },
        TaleasLogo: {
            width: '75px',
            height: '75px',
            flexShrink: 0,
        },
        paragraph: {
            display: 'flex',
            flexDirection: 'row',
        },
        paragraphcolumn: {
            display: 'flex',
            flexDirection: 'column',
            gap: 24
        },
        faqStyles: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 24
        },
        rowContainerFirst: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
        },
        rowContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8
        },
        faqRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8
        },
        TrippleLinesLogoFront: {
            width: '54px',
            height: 'auto',
            marginTop: '-14%'
        },

        TrippleLinesLogo: {
            width: '54px',
            height: '44px',
            transform: 'rotate(118deg)',
            flexShrink: 0,
            marginTop: '-5%'
        },
        TrippleLinesLogoStyles: {
            width: '54px',
            height: 'auto',
            transform: 'rotate(118deg)',
            marginTop: '-11%',
        },
        TrippleLinesLogo2: {
            width: '54px',
            height: 'auto',
            transform: 'rotate(118deg)',
            marginTop: '-8%',
            marginLeft: '-2%'
        },

        trippleLinesContainer: {
            position: 'relative',
            display: 'flex',
        },
        trippleLinesLogo: {
            position: 'absolute',
            width: '54px',
            height: 'auto',
            transform: 'rotate(123deg)',
            top: -44,
            left: -10,
        },
        ToptrippleLinesLogo: {
            position: 'absolute',
            width: '54px',
            height: 'auto',
            transform: 'rotate(15deg)',
            top: -37,
            left: -44,
        },
        rowContainerParagraphImage: {
            display: 'flex',
            flexDirection: 'row'
        },
        aboutUsTaleasImageContainer: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
        },
        aboutUsTaleasImage: {
            width: '90%',
            height: 'auto',
            maxHeight: 400,
            objectFit: 'contain'
        },
        aboutUsFixedImage: {
            display: 'flex',
            flex: 1,
            position: 'absolute',
            alignSelf: 'flex-end',
            zIndex: -4,
            right: '-73px',
            top: -42,
            width: '40%',
            height: 'auto',
            objectFit: 'cover'
        },
        aboutUsFixedImageBigger: {
            display: 'flex',
            flex: 1,
            position: 'absolute',
            alignSelf: 'flex-end',
            zIndex: -4,
            right: '-73px',
            top: -42,
            width: '45%',
            height: 'auto',
            objectFit: 'cover'
        },
        imageFixContainer: {
            display: 'flex',
            position: 'relative'
        },
        image1: {
            width: '75%'
        },
        image1Smaller: {
            width: '65%'
        },
        image2: {
            width: '75%'
        },
        image2Smaller: {
            width: '65%'
        },
        image3: {
            width: '75%'
        },
        image3Smaller: {
            width: '65%'

        },

    },
    laptop: {
        wrapper: {
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: 'calc(100% - 345px)',
            display: 'flex',
            overflow: 'scroll',
            padding: '64px',
        },
        wrapper2: {
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: 'calc(100% - 345px)',
            display: 'flex',
            overflow: 'scroll',
            padding: '64px',
        },
        bigContainerBigScreenHandle: {
            display: 'flex'
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 64,
        },
        PurpleAboutLogo: {
            width: '288px',
            height: '268px',
            flexShrink: 0,
            position: 'absolute',
            top: '-5%',
            right: '40%',
        },
        blueAboutLogo: {
            width: '246px',
            height: '229px',
            transform: 'rotate(351deg)',
            flexShrink: 0,
            position: 'absolute',
            top: '70%',
            left: '-6%',
            display: 'none'
        },
        ElephantLineLogo: {
            width: '714px',
            height: '711px',
            flexShrink: 0,
            position: 'absolute',
            bottom: '0%',
            left: '-7%',
            zIndex: -54,
        },
        innerContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 64,
        },

        imageFixContainer: {
            display: 'flex',
            position: 'relative'
        },
        containerAboutTaleas: {
            display: 'flex',
            flexDirection: 'column',
            gap: 24
        },
        TaleasLogo: {
            width: '75px',
            height: '75px',
            flexShrink: 0,
        },
        paragraph: {
            display: 'flex',
            flexDirection: 'row',
        },
        faqStyles: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width: '95%',
            gap: 24,
            paddingBottom: 24
        },
        rowContainerFirst: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            gap: 8,

        },
        rowContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            gap: 8
        },
        faqRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8
        },
        TrippleLinesLogoFront: {
            width: '54px',
            marginTop: '-29%'
        },
        TrippleLinesLogo: {
            width: '54px',
            height: '44px',
            transform: 'rotate(118deg)',
            flexShrink: 0,
            marginTop: '-5%'
        },
        TrippleLinesLogo2: {
            width: '54px',
            transform: 'rotate(118deg)',
            marginTop: '-12%'
        },
        TrippleLinesLogoStyles: {
            width: '54px',
            transform: 'rotate(118deg)',
            marginTop: '-15%',
        },
        trippleLinesContainer: {
            position: 'relative',
            display: 'flex',
        },
        trippleLinesLogo: {
            position: 'absolute',
            width: '54px',
            height: 'auto',
            transform: 'rotate(123deg)',
            top: -44,
            left: -10,
        },
        ToptrippleLinesLogo: {
            position: 'absolute',
            width: '54px',
            height: 'auto',
            transform: 'rotate(15deg)',
            top: -37,
            left: -44,
        },
        rowContainerParagraphImage: {
            display: 'flex',
            flexDirection: 'row'
        },
        aboutUsTaleasImageContainer: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
        },
        aboutUsTaleasImage: {
            width: '100%',
            height: 'auto',
            maxHeight: 400,
            objectFit: 'contain'
        },
        aboutUsFixedImage: {
            display: 'flex',
            flex: 1,
            position: 'absolute',
            alignSelf: 'flex-end',
            zIndex: -4,
            right: '-97px',
            top: -49,
            width: '55%',
            height: 'auto',
            objectFit: 'cover'
        },
        image1: {
            width: '75%'
        },
        image1Smaller: {
            width: '65%'
        },
        image2: {
            width: '75%'
        },
        image2Smaller: {
            width: '65%'
        },
        image3: {
            width: '75%'
        },
        image3Smaller: {
            width: '65%'

        },


    },
    tablet: {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: '0 64px',
            overflowX: 'hidden',
        },
        wrapper2: {
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: '0px 48px',
            overflowX: 'hidden',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        topIconsRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '48px 0px',
            marginBottom: 24
        },
        containerAboutTaleas: {
            display: 'flex',
            flexDirection: 'column',
            gap: 24
        },
        taleasLogo: {
            width: 60,
            height: 'auto',
        },
        navbarOpener: {
            width: 42,
            height: 'auto',
        },
        rowContainerFirst: {
            display: 'flex',
            flexDirection: 'row',
            gap: 4
        },
        TrippleLinesLogoFront: {
            transform: 'rotate(135deg)',
            width: 42,
            height: 'auto',
            alignSelf: 'flex-start',
            marginTop: '-3%'
        },
        TrippleLinesLogoFrontB: {
            transform: 'rotate(135deg)',
            width: 54,
            height: 'auto',
            alignSelf: 'flex-start',
            marginTop: '-5%'
        },
        trippleLinesContainer: {
            position: 'relative',
            display: 'flex',
        },
        trippleLinesLogo: {
            position: 'absolute',
            width: '54px',
            height: 'auto',
            transform: 'rotate(123deg)',
            top: -44,
            left: -10,
        },
        coloredText: {
            width: 'fit-content',
            height: 'fit-content'
        },
        innerContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: 64,
            marginBottom: 32
        },
        paragraph: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 24
        },
        faqRowContainer: {
            marginTop: 50,
            display: 'flex',
            flexDirection: 'row'
        },
        TrippleLinesLogo2: {
            transform: 'rotate(135deg)',
            width: 54,
            height: 44,
            alignSelf: 'flex-start',
            marginTop: '-3.5%'
        },
        faqStyles: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: 16
        },
        allAboutUsImages: {
            alignSelf: 'center',
            width: '80%',
            height: 'auto'
        },
        aboutUsTaleasImageContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        aboutUsTaleasImage: {
            width: '60%',
            height: 'auto'
        },

    },
    mobile: {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: '0px 16px',
            overflowX: 'hidden'
        },
        wrapper2: {
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url('${BackgroundPhoto}')`,
            backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: '0px 16px',
            overflowX: 'hidden'
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        PurpleAboutLogo: {
            width: '214px',
            height: '138px',
            flexShrink: 0,
            position: 'absolute',
            top: '-5%',
            right: '4%',
        },
        blueAboutLogo: {
            width: '246px',
            height: '169px',
            transform: 'rotate(351deg)',
            flexShrink: 0,
            position: 'absolute',
            top: '88%',
            left: '68%',
        },
        ElephantLineLogo: {
            width: '714px',
            height: '711px',
            flexShrink: 0,
            position: 'absolute',
            bottom: '0%',
            left: '-7%',
            zIndex: -54,
        },
        topIconsRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '24px 0px',
            marginBottom: 48
        },
        taleasLogo: {
            width: 60,
            height: 'auto',
        },
        navbarOpener: {
            width: 42,
            height: 'auto',
        },
        rowContainerFirst: {
            display: 'flex',
            flexDirection: 'row',
            gap: 4
        },
        TrippleLinesLogoFront: {
            transform: 'rotate(135deg)',
            width: 42,
            height: 31,
            alignSelf: 'flex-start',
            marginTop: '-5%'
        },
        TrippleLinesLogoFrontB: {
            transform: 'rotate(135deg)',
            width: 54,
            height: 44,
            alignSelf: 'flex-start',
            marginTop: '-8%'
        },
        trippleLinesContainer: {
            position: 'relative',
            display: 'flex',
        },
        containerAboutTaleas: {
            display: 'flex',
            flexDirection: 'column',
            gap: 24
        },
        trippleLinesLogo: {
            position: 'absolute',
            width: '54.05px',
            height: 'auto',
            transform: 'rotate(123deg)',
            top: -44,
            left: -10,
        },
        coloredText: {
            width: 'fit-content',
            height: 'fit-content'
        },
        innerContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: 64,
            marginBottom: 32
        },
        paragraph: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 24
        },
        faqRowContainer: {
            marginTop: 50,
            display: 'flex',
            flexDirection: 'row'
        },
        TrippleLinesLogo2: {
            transform: 'rotate(135deg)',
            width: 54,
            height: 44,
            alignSelf: 'flex-start',
            marginTop: '-8%'
        },
        faqStyles: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: 16
        },
        aboutUsTaleasImageContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        aboutUsTaleasImage: {
            width: '100%',
            height: 'auto'
        },
    }

}



export default AboutStyles