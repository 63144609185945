import React from 'react'
import HomeStyles from './Home.Styles'
import Text from '../../components/Text/Text'
import TextContainerColored from '../../components/TextContainerColored/TextContainerColored'
//Handel import with one file on the same folder.
// import HomeImage from '../../utils/images/Home Image.png'
import CardLine from '../../utils/images/Group.svg'
import Star from '../../utils/images/gif star.svg'
import TaleasLogo from '../../utils/images/TaleasLogo.svg'
import Omg from '../../utils/images/OMG.svg'
import Now from '../../utils/images/Now.svg'
import Free from '../../utils/images/Free.svg'
// import small1 from '../../utils/images/curiosity.png'
import img1 from '../../utils/images/img1.png'
import img2 from '../../utils/images/img2.png'
import img3 from '../../utils/images/img3.png'
import img4 from '../../utils/images/img4.png'
import img5 from '../../utils/images/img5.png'
import img6 from '../../utils/images/img6-1.png'
// import TrippleLines from '../../utils/images/TrippleLines.svg'
import navbarOpener from '../../utils/images/navbarOpener.svg'
// import HomeTabletPIc from '../../utils/images/HomeTabletPIc.png'
// import TaleasMobileHome from '../../utils/images/TaleasMobileHome.png'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { useNavigate } from 'react-router-dom'
import '../index.css'
import { ImageSlider } from '../../components/Carousel/Carousel'

//This is not how you use eslint


const IMAGES = [
  { url: img1, alt: "Image One", },
  { url: img2, alt: "Image Two", },
  { url: img3, alt: "Image Three", },
  { url: img4, alt: "Image Four", },
  { url: img5, alt: "Image Five", },
  { url: img6, alt: "Image Six", },
]

const IMAGES2 = [
  // { url: HomeTabletPIc, alt: "Car One" },
  // { url: HomeTabletPIc, alt: "Car Two" },
  // { url: HomeTabletPIc, alt: "Car Three" },
  // { url: HomeTabletPIc, alt: "Car Four" },
  // { url: HomeTabletPIc, alt: "Car Five" },
  { url: img1, alt: "Image One", },
  { url: img2, alt: "Image Two", },
  { url: img3, alt: "Image Three", },
  { url: img4, alt: "Image Four", },
  { url: img5, alt: "Image Five", },
  { url: img6, alt: "Image Six", },
]

// const IMAGES3 = [
//   { url: HomeTabletPIc, alt: "Car One" },
//   { url: HomeTabletPIc, alt: "Car Two" },
//   { url: HomeTabletPIc, alt: "Car Three" },
//   { url: HomeTabletPIc, alt: "Car Four" },
//   { url: HomeTabletPIc, alt: "Car Five" },
// ]

// eslint-disable-next-line react/prop-types
const Home = ({ showNavbar, setShowNavbar }) => {
  const Dimension = useWindowDimensions()
  const navigate = useNavigate()
  //Very confusing
  const styles = Dimension.width > 1600 ? { ...HomeStyles.desktop } : Dimension.width > 1023 && Dimension.height < 1600 ?
    { ...HomeStyles.laptop } : Dimension.width > 930 && Dimension.width < 1024 ? { ...HomeStyles.tablet } : { ...HomeStyles.mobile }


  const handleRoutingApply = () => {
    navigate('/mainmenu')
    setShowNavbar(false)
  }

  const applicationState = false


  const TabletView = () => {
    return (
      <div>
        <div style={styles.textContainer}>
          {/* Inline Styles */}
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div style={styles.boldTextContainer}>
              <div style={styles.rowContainerTaleas}>
                <Text text='Taleas' textWeight='extraBold' size='h2' align='left' style={styles.textWidth} />
              </div>
              <div style={styles.coloredContainer}>
                <Text text='Talents  ' textWeight='extraBold' size='h2' align='left' style={styles.textWidth} />
                <Text text=' with ' textWeight='extraBold' size='h2' align='left' style={styles.textWidth} />
                {/* Why you create Colors on utilites */}
                <TextContainerColored text=' ideas' color='#F97171' align='center' size='h2' textStyles={styles.textColoredContainer} style={styles.heightColored} />
              </div>
            </div>
            <Text text='Taleas is an educational program that identifies young talents and equips them with skills for a journey in digital transformation.' align='left' size='h5' style={styles.textFixedWidth} />
            <div style={styles.applyContainer}>
              <div style={styles.applyButton} onClick={() => handleRoutingApply()}>
                Apply
              </div>
              <img src={Now} alt='Now icon to give better understanding of beside content' style={styles.logoSize} />
            </div>
          </div>
          <div style={styles.imageContainer}>
            <ImageSlider images={IMAGES2} />
          </div>
        </div>
        <div style={styles.cardContainer}>
          <div>
            <div style={styles.applicationInsideCardContainer}>
              {!applicationState ?
                <Text text={'Applications are closed and will open soon!'} size={'note'} align={'left'} style={styles.textApplicationClosed} />
                :
                <><div style={styles.rowTextContainer}>
                  <Text text={'Applications are open from:'} size={'note'} align={'left'} style={{ marginBottom: '24px' }} />
                  <Text text='21 June - 07 July' size='body' textWeight='bold' align='left' />
                </div><div style={styles.starContainer}>
                    <img src={Star} alt='a star inside of a card' style={styles.starImageStyles} />
                  </div></>}
            </div>
            <div style={styles.imageCardLine}>
              <img src={CardLine} alt='a line below the card to give a special look' style={styles.imageSize} />
            </div>
          </div>
          <img src={Omg} alt='an icon to give the page more exicting and good vibes' style={styles.omgLogo} />
        </div>
      </div>
    )
  }


  return (
    <>
      {Dimension.width < 1024 && showNavbar ? null : <div style={Dimension.height > 800 ? styles.wrapper : styles.wrapper2} className='wrapper'>
        {Dimension.width < 1024 ? <div style={styles.topIconsRow}>
          <img src={TaleasLogo} alt='a logo that identifies taleas' style={styles.taleasLogo} />
          <img src={Free} alt='an icon that displays that everything is free' style={styles.freeLogo} />
          <img src={navbarOpener} alt='an icon to help with the functionality of opening navbar' style={styles.navbarOpener} onClick={() => setShowNavbar(true)} />
        </div> : null}

        <div style={styles.container}>

          <div style={styles.textContainer}>
            {Dimension.width > 930 && Dimension.width < 1024 ? <TabletView /> : <>
              <div style={styles.boldTextContainer}>
                <div style={styles.rowContainerTaleas}>
                  <Text text='Taleas' textWeight='extraBold' size='h2' align='left' style={styles.textWidth} />
                </div>
                <div style={styles.coloredContainer}>
                  <Text text='Talents  ' textWeight='extraBold' size='h2' align='left' style={styles.textWidth} />
                  <Text text=' with ' textWeight='extraBold' size='h2' align='left' style={styles.textWidth} />
                  <TextContainerColored text=' ideas' color='#F97171' align='center' textStyles={styles.textColoredContainer} style={Dimension.width > 1023 ? null : styles.heightColored} />
                </div>
              </div>
              <Text text={'Taleas is an educational program that identifies young talents and equips them with skills for a journey in digital transformation.'} align={'left'} size={'h5'} style={styles.textFixedWidth} />
              <div style={styles.applyContainer} className='applyContainer'>
                <div style={styles.applyButton} className='applyButton' onClick={() => handleRoutingApply()}>
                  Apply
                </div>
                <img src={Now} alt='Now icon to give better understanding of beside content' style={styles.logoSize} />
              </div>
              <div style={styles.cardContainer}>
                <div>

                  <div style={styles.applicationInsideCardContainer}>
                    {!applicationState ? <Text text={'Applications are closed and will open soon!'} size={'note'} align={'left'} style={styles.textApplicationClosed} />
                      :
                      <>
                        <div style={styles.rowTextContainer}>
                          <Text text={'Applications are open from:'} size={'note'} align={'left'} />
                          <Text text={'21 June - 07 July'} size={'body'} textWeight={'bold'} align={'left'} />
                        </div><div style={styles.starContainer}>
                          <img src={Star} alt='a star inside of a card' style={styles.starImageStyles} />
                        </div>
                      </>
                    }
                  </div>
                  <div style={styles.imageCardLine}>
                    <img src={CardLine} alt='a line below the card to give a special look' style={styles.imageSize} />
                  </div>
                </div>
                <img src={Omg} alt='an icon to give the page more exicting and good vibes' style={styles.omgLogo} />
              </div>
              {Dimension.width < 1024 && <div style={styles.imageContainer}>
                {Dimension.width > 550 ? <ImageSlider images={IMAGES2} /> : <ImageSlider images={IMAGES2} />}
                {/* <img src={Dimension.width > 550 ? HomeTabletPIc : TaleasMobileHome} alt='main picture displaying an ex taleas member' style={styles.image} /> */}
              </div>}
            </>
            }
          </div>
          {Dimension.width > 1023 ?
            <div style={styles.imageContainer}>
              {Dimension.width > 1600 && <ImageSlider images={IMAGES} />}
              {Dimension.width > 1023 && Dimension.width < 1600 ? <ImageSlider images={IMAGES2} /> : null}
            </div> : null}
        </div>
      </div>}
    </>
  )
}

export default Home