import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './containers/Home/Home'
import About from './containers/About/About'
import Contact from './containers/Contact/Contact'
import OurProgram from './containers/OurProgram/OurProgram'
import Navbar from './components/Navbar/Navbar'
import { useEffect, useState } from 'react'
import useWindowDimensions from './hooks/useWindowDimensions'
import ScrollToTop from './hooks/ScrollToTop'
import GameScene from './TaleasMaze/Scenes/GameScene';
import GameManageProvider from './TaleasMaze/context/GameManager';
import MainMenu from './TaleasMaze/Scenes/MainMenu';
import { v4 as uuidv4 } from 'uuid';
function App() {
  const Dimension = useWindowDimensions()
  const [showNavbar, setShowNavbar] = useState(false)
  const id = uuidv4();
  useEffect(() => {
    if (Dimension.width > 1023) {
      setShowNavbar(true)
    } else {
      setShowNavbar(false)
    }
  }, [Dimension])



  return (
    <GameManageProvider>
      <BrowserRouter>
      <ScrollToTop />
        {showNavbar && Dimension.width < 1024 ? <Navbar setShowNavbar={setShowNavbar} /> : Dimension.width > 1023 ? <Navbar setShowNavbar={setShowNavbar} /> : null}
        <Routes>
          <Route index element={<Home setShowNavbar={setShowNavbar} showNavbar={showNavbar} />} />
          <Route path="about" element={<About setShowNavbar={setShowNavbar} showNavbar={showNavbar} />} />
          <Route path="contact" element={<Contact setShowNavbar={setShowNavbar} showNavbar={showNavbar} id={id} />} />
          <Route path="program" element={<OurProgram setShowNavbar={setShowNavbar} showNavbar={showNavbar} />} />
          <Route path="*" element={<Home setShowNavbar={setShowNavbar} showNavbar={showNavbar} />} />
          <Route path="mainmenu" element={<MainMenu />} />
          <Route path="game" element={<GameScene />} />
        </Routes>
      </BrowserRouter>
    </GameManageProvider>
  )
}

export default App
