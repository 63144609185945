import React from 'react';
import './GameFinished.css';

import { useGameManager } from '../context/GameManager';

import ShareScore from '../Components/ShareScore';
import Share from '../assets/Icons/share.svg';

import axios from 'axios';

import useScreenSize from '../hooks/useScreenSize';

export default function GameFinished() {
  const { score, time } = useGameManager();
  // const {imageURL, setImageURL} = useState("")

  const screensize = useScreenSize();
  const formatTime = timeInMilliseconds => {
    const minutes = Math.floor(timeInMilliseconds / (60 * 1000));
    const seconds = Math.floor((timeInMilliseconds % (60 * 1000)) / 1000);
    const milliseconds = Math.floor((timeInMilliseconds % 1000) / 10);

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    const formattedMilliseconds = String(milliseconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
  };



  async function shareImage() {
    // URL of the image you want to download and share
    try {
      const dataImage = {
        taleasMazeScore: score,
      };
      const response = await axios.post(
        'https://qsw8nrhwvb.execute-api.eu-central-1.amazonaws.com/prod/shareScore',
        dataImage,
      );


      console.log("🚀 ~ shareImage ~ responseImage:", response.data.imageURL)
      const imageURL = response.data.imageURL
      // Fetch the image
      const response2 = await fetch(imageURL);
      console.log(response2);
      const blob = await response2.blob();
      console.log(blob);

      const data = {
        files: [
          new File([blob], "image.png", { type: blob.type })
        ],
        title: 'Taleas Score',
        text: `Hey i just applied for the tales program and played their game and got a score of ${score}, you can apply too at https://taleas.io/`,
      }
      console.log(data)
      // Share the image using the Web Share API
      if (navigator.share) {
        await navigator.share(data);
      } else {
        // Fallback if Web Share API is not supported
        alert('Web Share API is not supported on this browser.');
      }

      // Clean up the temporary URL
      // URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error('Error sharing image:', error);
    }
  }

  return (
    <div className="game-over-screen">
      <div className="game-over-body">
        <div className="main-text">Thanks for applying!</div>
        <div className="thank-you-text">
          Your application is sent and will be reviewed by our team.
        </div>
        <div className="thank-you-text">
          Meanwhile, share your score with your friends and invite them to
          participate.
        </div>
        {screensize.width < 768 &&
          <div className="share-score-btn-mobile" onClick={shareImage}>
            <span>Share your score</span>
            <img src={Share} alt="share" />
          </div>
        }
      </div>
      <div className="game-over-footer">
        <ShareScore score={score} time={formatTime(time)} />
      </div>
    </div>
  );
}
