import Colors from "../../utils/colors/Colors"

const TextStyles = {
  defaultStyles: {
    display: 'flex',
    fontSize: 16,
    color: Colors.primaryText,
    fontFamily: 'Open Sans',
    width: '100%',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'pre-line'
  },
  defaultTablet: {
    fontSize: 18,
  },
  defaultDesktop: {
    fontSize: 18,
  },
  h1: {
    mobile: {
      fontSize: 32,
      lineHeight: 1,
    },
    tablet: {
      fontSize: 56,
      lineHeight: 1,
    },
    desktop: {
      fontSize: 64,
      lineHeight: 1,
    },
  },
  h2: {
    mobile: {
      fontSize: 28,
      lineHeight: 1,
    },
    tablet: {
      fontSize: 40,
      lineHeight: 1,
    },
    desktop: {
      fontSize: 56,
      lineHeight: 1,
    },
  },
  h3: {
    mobile: {
      fontSize: 24,
      lineHeight: 1,
    },
    tablet: {
      fontSize: 34,
      lineHeight: 1,
    },
    desktop: {
      fontSize: 32,
      lineHeight: 1,
    },
  },
  h4: {
    mobile: {
      fontSize: 20,
      lineHeight: 1,
    },
    tablet: {
      fontSize: 24,
      lineHeight: 1,
    },
    desktop: {
      fontSize: 28,
      lineHeight: 1,
    },
  },
  h5: {
    mobile: {
      fontSize: 18,
      lineHeight: 1,
    },
    tablet: {
      fontSize: 20,
      lineHeight: 1,
    },
    desktop: {
      fontSize: 20,
      lineHeight: 1,
    },
  },
  body: {
    mobile: {
      fontSize: 16,
      lineHeight: 1,
    },
    tablet: {
      fontSize: 18,
      lineHeight: 1,
    },
    desktop: {
      fontSize: 18,
      lineHeight: 1,
    },
  },
  note: {
    mobile: {
      fontSize: 16,
      lineHeight: 1,
    },
    tablet: {
      fontSize: 16,
      lineHeight: 1,
    },
    desktop: {
      fontSize: 16,
      lineHeight: 1,

    },
  },
  footnote: {
    mobile: {
      fontSize: 14,
      lineHeight: 1,

    },
    tablet: {
      lineHeight: 1,

      fontSize: 14,
    },

    desktop: {

      lineHeight: 1,
      fontSize: 14,
    },
  },
}

export default TextStyles