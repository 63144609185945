import React from 'react';

import './ShareScore.css';

import Smiley from '../assets/sketches/Smiley.svg';
import Star from '../assets/sketches/Star.svg';
import Astro from '../assets/Images/Astronaut.svg';
import TaleasLogo from '../assets/Images/TaleasDownload.svg';



// eslint-disable-next-line react/prop-types
const ShareScore = ({score}) => {
  
  return (
    <div className="main-share-container">
      <div className="share-container">
        <img src={Smiley} alt="smile" className="smile" />
        <img src={Star} alt="star" className="star" />
        <img src={Astro} alt="astronaut" />
        <div className="share-title">
          I just applied for the Taleas program.
        </div>
        <div className="game-score-text">
          <span style={{fontSize: '16px'}}>My score</span>
          <div className="question-score">
            <span>{score}/</span>{' '}
            <span style={{fontWeight: 'normal', fontSize: '14px'}}>6</span>
          </div>
        </div>
        <img src={TaleasLogo} alt="gold" className="taleas-logo-share" />
      </div>
    </div>
  );
};

export default ShareScore;
