
//eslint-disable-next-line react/no-unescaped-entities
import React from 'react';
import Badge1 from '../assets/MainMenu/Badge1.svg';
import Badge2 from '../assets/MainMenu/Badge2.svg';
import Badge3 from '../assets/MainMenu/Badge3.svg';
import './MainMenu.css';
import Info from '../assets/Icons/info.svg';
import Gift from '../assets/Images/keisibotmm.svg';
import { useNavigate } from 'react-router-dom';
import useScreenSize from "../hooks/useScreenSize";
import { useGameManager } from '../context/GameManager';
import Lines from "../assets/sketches/lines.svg";
import Wow from "../assets/sketches/wow.svg";
import Arrow from "../assets/sketches/arrow.svg";
import SadFace from '../assets/sketches/sadface.svg';

export default function MainMenu() {
  const navigate = useNavigate();
  const redirecttoApply = () => {
    navigate("/contact");
  };
  const {restartGame} = useGameManager()
  const redirectToGame = () => {
    restartGame();
    navigate("/game")
  }
  const screensize = useScreenSize();

  return (
    <div className="main-menu">
      <div className="main-title">Before applying let&apos;s play a game!</div>
      <div className="instructions">
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <img src={Info} alt="" />
          <span>Instructions:</span>
        </div>
        <span>
          Welcome to Taleas Maze, a simple yet engaging game where you collect
          letters and complete quizzes, {screensize.width > 768 && <br />}
          all while navigating through the maze. Use the WASD keys to move and
          enjoy the challenge!
        </span>
      </div>
      <div className="mm-body-container">
        <div className="main-menu-body">
          <div className="red-badge">Objectives:</div>
          <ul className="instructions-list">
            <li className="is-li">
              <img src={Badge1} alt="" className="is-img" />
              <span>Collect all the letters scattered throughout each level.</span>
            </li>
            <li className="is-li">
              <img src={Badge2} alt="" className="is-img" />
              <span>Challenge your knowledge by correctly answering quiz questions.</span>
            </li>
            <li className="is-li">
              <img src={Badge3} alt="" />
              <span>Enjoy the experience and share it with others!</span>
            </li>
          </ul>
          <div className="main-menu-buttons">
            <div className="go-to-game">
              <button className="start-btn" onClick={redirectToGame}>
                Start Game
              </button>
              <img src={Arrow} alt="" className="arrow" />
              <img src={Wow} alt="" className="wow" />
              <img src={Lines} alt="" className="lines" />
            </div>

            <div className="go-to-apply">
              <button className="dont-btn" onClick={() => redirecttoApply()}>
                Skip to application
              </button>
              <img src={SadFace} alt="" className="sad-face" />
            </div>
          </div>
        </div>
        <img src={Gift} alt="" className="gift-image" />
      </div>
    </div>
  );
}