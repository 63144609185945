import Colors from "../../utils/colors/Colors"
import BackgroundPhoto from '../../utils/images/Background.svg'
// import BackgroundMobileAbout from '../../utils/images/BackgroundMobileAbout.svg'

const ContactStyles = {
    desktop: {
        wrapper: {
            width: 'calc(100% - 455px)',
            display: 'flex',
            backgroundImage: `url('${BackgroundPhoto}')`,
           backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            flexDirection: 'column',
            padding: '32px 100px 3px 100px',
            position: 'relative',
            // height:'100%'
        },
        wrapper3: {
            width: 'calc(100% - 455px)',
            display: 'flex',
            backgroundImage: `url('${BackgroundPhoto}')`,
           backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            flexDirection: 'column',
            padding: '32px 100px 32px 100px',
            position: 'relative',
            // height:'100%'
        },
        wrapper2: {
            width: 'calc(100% - 455px)',
            display: 'flex',
            backgroundImage: `url('${BackgroundPhoto}')`,
           backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            flexDirection: 'column',
            padding: '32px 100px 32px 100px',
            position: 'relative',
            // height:'100%',
            zIndex:'-1'
        },
        bigContainerBigScreenHandle: {
            display: "flex",
            flexDirection: 'column',
        },
        formMessageStyles: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 24,
            width: 360,
            border: '1px solid #000',
            boxShadow: '4px 4px 0px 0px #000',
            position: 'absolute',
            background: Colors.successForm,
            gap: 4,
            padding: 8,
            bottom: 32,
            right: 32,
        },
        formMessageStylesError: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 24,
            width: 'max-content',
            border: '1px solid #000',
            boxShadow: '4px 4px 0px 0px #000',
            position: 'absolute',
            background: Colors.errorForm,
            padding: 8,
            bottom: 32,
            right: 32,
        },
        innerFormMessageContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1
        },
        rowContainerformMessageStyles: {
            display: 'flex',
            flex: 1,
            gap: 4,
        },
        formMessageIcon: {
            width: 40,
            height: 'auto',
            cursor: 'pointer'
        },
        errorMessageInput: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            justifyContent: 'space-between'
        },
        innerContainer: {
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 1900,
            alignSelf: 'flex-start',
            width: '100%'
        },
        innerContainer2: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        columnHeaderContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 38
        },
        inputFieldContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 32
        },
        rowInputContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            marginBottom: 24
        },
        inputField: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            width: '-webkit-fill-available'
        },
        inputField2: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            width: '-webkit-fill-available'
        },
        textAreaContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        uploadFileContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 11,
            marginBottom: 32
        },
        uploadFileRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            alignItems: 'center',
            marginBottom: 2,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        button: {
            display: 'flex',
            width: 223,
            background: Colors.white,
            height: 48,
            padding: '0px 32px',
            flex: 1,
            marginRight: 32,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            borderRadius: 6,
            border: '1px solid #000',
            boxShadow: '4px 4px 0px 0px #000',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
            cursor: 'pointer'
        },
        buttonDisabled: {
            display: 'flex',
            width: 223,
            background: Colors.white,
            height: 48,
            padding: '0px 32px',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            borderRadius: 6,
            color: '#7C7C7C',
            border: '1px solid #7C7C7C',
            boxShadow: '4px 4px 0px 0px #7C7C7C',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
        },
        buttonF: {
            display: 'flex',
            width: 223,
            height: 48,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            gap: 10,
            borderRadius: 6,
            border: '1px solid #000',
            color: Colors.white,
            background: '#764FBE',
            boxShadow: '4px 4px 0px 0px #000',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
            cursor: 'pointer'
        },

        doubleImageContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 200,
            justifyContent: 'space-around'
        },
        imgContainer: {
            position: 'relative'
        },
        springArrowContainer: {
            position: 'relative',
            display: 'flex',
        },
        springArrowLogo: {
            width: '80px',
            height: 'auto',
            transform: 'rotate(2deg)',
            position: 'absolute',
            left: -113,
            top: -45
        },
        taleasLogo: {
            width: 75,
            height: 'auto',
        },
        redStarsLogo: {
            width: 60,
            height: 'auto',
        },
        inputFieldFix: {
            display: 'flex',
            width: '-webkit-fill-available',
            height: 48,
            padding: '0px 16px',
            alignItems: 'flex-start',
            gap: 10,
            alignSelf: 'stretch',
            borderRadius: '6px',
            border: '1px solid #000',
            background: Colors.white,
            boxShadow: '4px 4px 0px 0px #000',
        },
        taleasPhotoLogo: {
            width: '444.869px',
            height: '425.881px',
            transform: 'rotate(0.285deg)',
            alignSelf: 'center',
        },
        smileLogo: {
            width: '55.73px',
            height: '35.02px',
            transform: 'rotate(19.903deg)',
            marginLeft: '15%',
        },
        taleasBookLogo: {
            width: '28%',
            height: 'auto',
            objectFit: 'contain'
        },
        redStarLogo: {
            width: '41.32px',
            height: ' 61.917px',
            transform: 'rotate(4.555deg)',
        },
        smallerTaleasPhoto: {
            width: '75%',
            height: '75%',
            objectFit: 'contain'
        },
        trippleLinesContainer: {
            position: 'relative',
            display: 'flex',
        },
        trippleLinesLogo: {
            position: 'absolute',
            width: '54.05px',
            height: 'auto',
            transform: 'rotate(123deg)',
            top: -45,
            left: -7,
        },
        bookContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            width: '100%',
            justifyContent: 'space-evenly'
        },
        bigImageContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center'
        },
        spaceContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
    },
    laptop: {
        wrapper: {
            width: 'calc(100% - 345px)',
            display: 'flex',
            backgroundImage: `url('${BackgroundPhoto}')`,
           backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            flexDirection: 'column',
            overflow: 'scroll',
            overflowX: 'hidden',
            position: 'relative',
            padding: '64px',

        },
        wrapper2: {
            width: 'calc(100% - 345px)',
            display: 'flex',
            backgroundImage: `url('${BackgroundPhoto}')`,
           backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            flexDirection: 'column',
            height: 'fit-content',
            overflowX: 'hidden',
            overflow: 'scroll',
            position: 'relative',
            padding: '64px',

        },
        inputFieldFix: {
            display: 'flex',
            width: '-webkit-fill-available',
            height: 48,
            padding: '0px 16px',
            alignItems: 'flex-start',
            gap: 10,
            alignSelf: 'stretch',
            borderRadius: '6px',
            border: '1px solid #000',
            background: Colors.white,
            boxShadow: '4px 4px 0px 0px #000',
        },
        formMessageStyles: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 24,
            width: 360,
            border: '1px solid #000',
            boxShadow: '4px 4px 0px 0px #000',
            position: 'absolute',
            background: Colors.successForm,
            gap: 4,
            padding: 8,
            bottom: 32,
            right: 32,
        },
        formMessageStylesError: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 24,
            width: 'max-content',
            border: '1px solid #000',
            boxShadow: '4px 4px 0px 0px #000',
            position: 'absolute',
            background: Colors.errorForm,
            padding: 8,
            bottom: 32,
            right: 32,
        },
        innerFormMessageContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1
        },
        rowContainerformMessageStyles: {
            display: 'flex',
            flex: 1,
            gap: 4,
        },
        formMessageIcon: {
            width: 40,
            height: 'auto',
            cursor: 'pointer'
        },
        textArea: {
            width: 'auto'
        },
        innerContainer: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            gap: 32,
        },
        innerContainer2: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
        },
        columnHeaderContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 38,

        },
        inputFieldContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 32
        },
        rowInputContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            marginBottom: 16,
        },
        inputField: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            width: '-webkit-fill-available'
        },
        inputField2: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            width: '-webkit-fill-available'
        },
        textAreaContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        uploadFileContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginBottom: 32
        },
        uploadFileRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            alignItems: 'center',
            marginBottom: 2,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        button: {
            display: 'flex',
            width: 'fit-content',
            background: Colors.white,
            height: 48,
            flex: 1,
            padding: '0px 32px',
            marginRight: 32,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            borderRadius: 6,
            border: '1px solid #000',
            boxShadow: '4px 4px 0px 0px #000',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
            cursor: 'pointer'
        },
        buttonDisabled: {
            display: 'flex',
            width: 'fit-content',
            background: Colors.white,
            height: 48,
            flex: 1,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            color: '#7C7C7C',
            borderRadius: 6,
            border: '1px solid #7C7C7C',
            boxShadow: '4px 4px 0px 0px #7C7C7C',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
        },
        buttonF: {
            display: 'flex',
            width: 'fit-content',
            height: 48,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            gap: 10,
            borderRadius: 6,
            border: '1px solid #000',
            color: Colors.white,
            background: '#764FBE',
            boxShadow: '4px 4px 0px 0px #000',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
            cursor: 'pointer'
        },

        doubleImageContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around'
        },
        imgContainer: {
            position: 'relative'
        },
        springArrowContainer: {
            position: 'relative',
            display: 'flex',
        },
        springArrowLogo: {
            width: '92px',
            height: 'auto',
            transform: 'rotate(2deg)',
            position: 'absolute',
            left: -113,
            top: -62
        },

        taleasLogo: {
            width: 75,
            height: 'auto',
        },
        redStarsLogo: {
            width: 60,
            height: 'auto',
        },

        taleasPhotoLogo: {
            width: '444.869px',
            height: '425.881px',
            transform: 'rotate(0.285deg)',
            alignSelf: 'center',
        },
        smileLogo: {
            width: '55.73px',
            height: '35.02px',
            transform: 'rotate(19.903deg)',
            marginLeft: '15%',
        },
        taleasBookLogo: {
            width: '50%',
            height: 'auto',
            objectFit: 'contain'
        },
        redStarLogo: {
            width: '41.32px',
            height: ' 61.917px',
            transform: 'rotate(4.555deg)',
        },
        smallerTaleasPhoto: {
            width: '85%',
            height: '75%',
            objectFit: 'contain'
        },
        trippleLinesContainer: {
            position: 'relative',
            display: 'flex',
        },
        trippleLinesLogo: {
            position: 'absolute',
            width: '54.05px',
            height: ' 44.15px',
            transform: 'rotate(123deg)',
            top: -32,
            left: -11,
        },
        bookContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-evenly'
        },
        bigImageContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center'
        },
        spaceContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
    },
    tablet: {
        wrapper: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            padding: '0px 16px',
            overflowX: 'hidden',
            backgroundImage: `url('${BackgroundPhoto}')`,
           backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            objectFit: 'cover'
        },
        wrapper2: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            padding: '0px 16px',
            overflowX: 'hidden',
            backgroundImage: `url('${BackgroundPhoto}')`,
           backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            objectFit: 'cover'
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        formMessageStyles: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 24,
            width: 360,
            border: '1px solid #000',
            boxShadow: '4px 4px 0px 0px #000',
            position: 'absolute',
            background: Colors.successForm,
            gap: 4,
            padding: 8,
            bottom: 32,
            right: 32,
        },
        formMessageStylesError: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 24,
            width: 'max-content',
            border: '1px solid #000',
            boxShadow: '4px 4px 0px 0px #000',
            position: 'absolute',
            background: Colors.errorForm,
            padding: 8,
            bottom: 32,
            right: 32,
        },
        innerFormMessageContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1
        },
        rowContainerformMessageStyles: {
            display: 'flex',
            flex: 1,
            gap: 4,
        },
        formMessageIcon: {
            width: 40,
            height: 'auto',
            cursor: 'pointer'
        },
        innerContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        topIconsRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '48px 0px'
        },
        taleasLogo: {
            width: 60,
            height: 'auto',
        },
        textBecomePartOfUs: {
            marginBottom: 24
        },
        navbarOpener: {
            width: 42,
            height: 'auto',
        },
        inputField: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginBottom: 24
        },
        inputField2: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginBottom: 24,
            marginTop: -8
        },
        textAreaContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginBottom: 16
        },
        uploadFileContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            marginBottom: 16
        },
        uploadFileRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
        },

        buttonContainer: {
            display: 'flex',
            flexDirection: 'column-reverse',
            gap: 26,
            alignItems: 'center',
            position: 'relative'
        },
        button: {
            display: 'flex',
            width: '-webkit-fill-available',
            background: Colors.white,
            height: 48,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            borderRadius: 6,
            border: '1px solid #000',
            /* neobrutalisem shadow */
            boxShadow: '4px 4px 0px 0px #000',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
        },
        buttonDisabled: {
            display: 'flex',
            width: '-webkit-fill-available',
            background: Colors.white,
            height: 48,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            borderRadius: 6,
            color: '#7C7C7C',
            border: '1px solid #7C7C7C',
            /* neobrutalisem shadow */
            boxShadow: '4px 4px 0px 0px #7C7C7C',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
        },
        buttonF: {
            display: 'flex',
            width: '100%',
            height: 48,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            borderRadius: 6,
            border: '1px solid #000',
            color: Colors.white,
            background: '#764FBE',
            boxShadow: '4px 4px 0px 0px #000',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
        },
        trippleLinesContainer: {
            position: 'relative',
            display: 'flex',
        },
        trippleLinesLogo: {
            position: 'absolute',
            width: '54.05px',
            height: '38.15px',
            transform: 'rotate(107deg)',
            top: -35,
            left: -24,
        },
        bottomImagesContainer: {
            display: 'flex',
            position: 'relative'
        },
        redStarsLogo: {
            position: 'absolute',
            width: '40.478px',
            height: '38.248px',
            right: '68%',
            marginTop: '10%'
        },
        mobileAboutTaleas: {
            position: 'absolute',
            right: '-23%',
            width: '250.478px',
            height: '228.248px',
        },
        taleasPhoto: {
            position: 'relative',
            marginTop: 38,
            right: '-53%',
            marginBottom: 28
        },
        FirstLine: {
            position: 'absolute',
            paddingLeft: 10
        },
        SecondLine: {
            position: 'absolute',
            paddingTop: 10
        },
        PhotoInsideLines: {
            position: 'absolute',
            height: 177,
            width: 195,
            paddingLeft: 28,
            paddingTop: 20
        },
        textAreaContainers: {
            display: 'flex',
            flex: 1
        },
        textArea: {
            display: 'flex',
        },
        inputFieldContainers: {
            display: 'flex',
            flex: 1,
        },
        inputFieldFix: {
            display: 'flex',
        },
        smileLogo: {
            width: 29.005,
            height: 18.226
        },
        smileCvContainer: {
            display: 'flex',
            flexDirection: 'row',
        }
    },
    mobile: {
        wrapper: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            padding: '0px 16px 0px 16px',
            overflowX: 'hidden',
            backgroundImage: `url('${BackgroundPhoto}')`,
           backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            objectFit: 'cover'
        },
        wrapper2: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            padding: '0px 16px 0px 16px',
            overflowX: 'hidden',
            backgroundImage: `url('${BackgroundPhoto}')`,
           backgroundRepeat:' no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            objectFit: 'cover'
        },
        formMessageStyles: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 24,
            width: 248,
            border: '1px solid #000',
            /* neobrutalisem shadow */
            boxShadow: '4px 4px 0px 0px #000',
            position: 'absolute',
            background: Colors.successForm,
            gap: 4,
            padding: 8,
            bottom: 32,
            right: 32,
        },
        formMessageStylesError: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 24,
            width: 'max-content',
            border: '1px solid #000',
            /* neobrutalisem shadow */
            boxShadow: '4px 4px 0px 0px #000',
            position: 'absolute',
            background: Colors.errorForm,
            padding: 8,
            bottom: 32,
            right: 32,
        },
        textBecomePartOfUs: {
            marginBottom: 24
        },
        innerFormMessageContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1
        },
        rowContainerformMessageStyles: {
            display: 'flex',
            flex: 1,
            gap: 4,
        },
        textError: {
            maxWidth: 200
        },
        formMessageIcon: {
            width: 40,
            height: 'auto',
            cursor: 'pointer'
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        innerContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        topIconsRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '24px 0px'
        },
        taleasLogo: {
            width: 60,
            height: 'auto',
        },
        navbarOpener: {
            width: 42,
            height: 'auto',
        },
        inputField: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginBottom: 24,
          
        },
        inputField2: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginBottom: 24,
            marginTop: -8
        },
        textAreaContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginBottom: 18
        },
        uploadFileContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            marginBottom: 16
        },
        uploadFileRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            marginTop: 10
        },

        buttonContainer: {
            display: 'flex',
            flexDirection: 'column-reverse',
            gap: 26,
            alignItems: 'center',
            position: 'relative'
        },
        buttonDisabled: {
            display: 'flex',
            width: '-webkit-fill-available',
            background: Colors.white,
            height: 48,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            borderRadius: 6,
            color: '#7C7C7C',
            border: '1px solid #7C7C7C',
            /* neobrutalisem shadow */
            boxShadow: '4px 4px 0px 0px #7C7C7C',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
        },
        button: {
            display: 'flex',
            width: '-webkit-fill-available',
            background: Colors.white,
            height: 48,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            borderRadius: 6,
            border: '1px solid #000',
            /* neobrutalisem shadow */
            boxShadow: '4px 4px 0px 0px #000',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
        },
        buttonF: {
            display: 'flex',
            width: '85%',
            height: 48,
            padding: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            borderRadius: 6,
            border: '1px solid #000',
            color: Colors.white,
            background: '#764FBE',
            boxShadow: '4px 4px 0px 0px #000',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
        },
        trippleLinesContainer: {
            position: 'relative',
            display: 'flex',
        },
        trippleLinesLogo: {
            position: 'absolute',
            width: '54.05px',
            height: '38.15px',
            transform: 'rotate(107deg)',
            top: -35,
            left: -24,
        },
        bottomImagesContainer: {
            display: 'flex',
            position: 'relative'
        },
        redStarsLogo: {
            position: 'absolute',
            width: '40.478px',
            height: '38.248px',
            right: '76%',
            marginTop: '10%'
        },
        mobileAboutTaleas: {
            position: 'absolute',
            right: '-23%',
            width: '250.478px',
            height: '228.248px',
        },
        taleasPhoto: {
            position: 'relative',
            marginTop: 38,
            right: '-38%',
            marginBottom: 28
        },
        FirstLine: {
            position: 'absolute',
            paddingLeft: 10
        },
        SecondLine: {
            position: 'absolute',
            paddingTop: 10
        },
        PhotoInsideLines: {
            position: 'absolute',
            height: 177,
            width: 195,
            paddingLeft: 28,
            paddingTop: 20
        },
        textAreaContainers: {
            display: 'flex',
            flex: 1
        },
        textArea: {
            display: 'flex',
        },
        inputFieldContainers: {
            display: 'flex',
            flex: 1,
        },
        inputFieldFix: {
            display: 'flex',
        },
        smileLogo: {
            width: 29.005,
            height: 18.226
        },
        smileCvContainer: {
            display: 'flex',
            flexDirection: 'row',
        }
    }
}

export default ContactStyles