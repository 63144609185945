

const TextContainerColoredStyles = {
    defaultStyles: {
        display: 'flex',

        flexShrink: 0,
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 10px',

        /* neobrutalisem shadow */
        boxShadow: '4px 4px 0px 0px #000',
    }
}

export default TextContainerColoredStyles