import React from 'react'
import PropTypes from 'prop-types'
import TextContainerColoredStyles from './TextContainerColored.Styles'
import Colors from '../../utils/colors/Colors'
import Text from '../Text/Text'

const TextContainerColored = props => {
  const styles = { ...TextContainerColoredStyles }

  const {
    text,
    color,
    size,
    align,
    textStyles,
    style
  } = props

  const backgroundColors = {
    backgroundColor: color
  }

  const allStyles = Object.assign({},
    styles.defaultStyles,
    backgroundColors,
    style
  );



  return (
    <div style={allStyles}>
      <Text text={text} textWeight={'extraBold'} size={size} color={'#fff'} align={align} style={textStyles} />
    </div>
  )
}

TextContainerColored.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  align: PropTypes.string,
  textStyles: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}

TextContainerColored.defaultProps = {
  color: Colors.white,
  size: 'h3',
  align: 'left'
}



export default TextContainerColored