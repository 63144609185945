import Colors from "../../utils/colors/Colors"

const InputFieldStyles = {
    desktop: {

        layout: {
            display: 'flex',
            width: '-webkit-fill-available',
            height: 48,
            padding: '0px 16px',
            alignItems: 'flex-start',
            gap: 10,
            alignSelf: 'stretch',
            borderRadius: '6px',
            border: '1px solid #000',
            background: Colors.white,
            boxShadow: '4px 4px 0px 0px #000',
        },
    },
    mobile: {
        layout: {
            display: 'flex',
            width: '-webkit-fill-available',
            height: 48,
            padding: '0px 16px',
            alignItems: 'flex-start',
            gap: 10,
            alignSelf: 'stretch',
            borderRadius: '6px',
            border: '1px solid #000',
            background: Colors.white,
            boxShadow: '4px 4px 0px 0px #000',
        },
    }
}

export default InputFieldStyles