import React, { useEffect, useRef, useState } from 'react'
import UploadFileStyles from './UploadFile.Styles'
import Colors from '../../utils/colors/Colors'
import Text from '../Text/Text'
import closeIconRed from '../../utils/images/closeIconRed.svg'
import uploadImage from '../../utils/images/uploadImage.svg'
import imageBeforeFileName from '../../utils/images/imageBeforeFileName.svg'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import PropTypes from 'prop-types'

const UploadFile = ({ imageFile,
  imageFileSecond,
  frontImageId,
  backImageId,
  setImageFileSecond,
  setImageFile,
  setFrontImageId,
  setBackImageId, }) => {
  const Dimension = useWindowDimensions()
  const styles = Dimension.width > 1440 ? { ...UploadFileStyles.desktop } : Dimension.width > 1023 && Dimension.width < 1441 ? { ...UploadFileStyles.laptop } : { ...UploadFileStyles.mobile }


  // const {

  // } = props


  const [loadingImageFile, setLoadingImageFile] = useState(false)

  const [errorMessage, setErrorMessage] = useState([{
    error: false, errorMessage: 'You can not upload the same file twice', id: 1
  }, {
    error: false, errorMessage: 'Size of files exceeds the given limit', id: 2
  },
  {
    error: false, errorMessage: 'File name must not contain special characters or spaces. Please remove any of the following characters from the file name: / \\ ? % * : | " < >', id: 3
  }])

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+=[\]{};':"\\|,<>/?~]/;
    return specialChars.test(str);
  }

  console.log(errorMessage[2].error, 'is error')

  console.log(containsSpecialChars(imageFile?.name), 'special')
  console.log(imageFile?.name, 'name')


  useEffect(() => {
    if (imageFile !== null || imageFileSecond !== null) {
      if (containsSpecialChars(imageFile?.name)) {
        setErrorMessage((prev) => ([...prev, errorMessage[2].error = true]))
        setImageFile(null)
      }

      if (containsSpecialChars(imageFileSecond?.name)) {
        setErrorMessage((prev) => ([...prev, errorMessage[2].error = true]))
        setImageFileSecond(null)
      }
    }

    if (imageFile !== null && imageFileSecond !== null) {
      const totalSize = imageFile.size + imageFileSecond.size
      if (totalSize >= 10000000 && imageFile !== null) {
        setErrorMessage((prev) => ([...prev, errorMessage[1].error = true]))
        setImageFileSecond(null)
      } else if (totalSize >= 10000000 && imageFileSecond !== null) {
        setErrorMessage((prev) => ([...prev, errorMessage[1].error = true]))
        setImageFile(null)
      } else {
        setErrorMessage((prev) => ([...prev, errorMessage[1].error = false]))
      }
    }
  }, [imageFile, imageFileSecond])

  const RenderImageInput = () => {

    const fileInputRef = useRef(null)

    const handleLabelClick = () => {
      fileInputRef.current.click()
    }

    const [dragActive, setDragActive] = useState(false);

    const handleDrag = function (e) {
      e.preventDefault();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };

    if (errorMessage[0].error) {
      setTimeout(() => { setErrorMessage((prev) => ([...prev, errorMessage[0].error = false])) }, 3000)
    }

    if (errorMessage[1].error) {
      setTimeout(() => { setErrorMessage((prev) => ([...prev, errorMessage[1].error = false])) }, 3000)
    }

    if (errorMessage[2].error) {
      setTimeout(() => { setErrorMessage((prev) => ([...prev, errorMessage[2].error = false])) }, 3000)
    }

    const handleDrop = function (e) {
      e.preventDefault();
      setDragActive(false);
      const file = e.dataTransfer.files[0]
      if (imageFile === null && imageFileSecond === null) {
        setImageFile(file)
        setLoadingImageFile(false)
      } else if (imageFile === null && imageFileSecond !== null) {
        if (file.name !== imageFileSecond.name) {
          setImageFile(file)
        } else {
          setErrorMessage((prev) => ([...prev, errorMessage[0].error = true]))
        }
      } else if (file.name !== imageFile.name) {
        setImageFileSecond(file)
        setLoadingImageFile(false)
      }
      else {
        setLoadingImageFile(false)
        setErrorMessage((prev) => ([...prev, errorMessage[0].error = true]))
      }
    };

    const handleFileChange = async event => {
      const file = event.target.files[0]
      event.target.value = ''
      if (imageFile === null && imageFileSecond === null) {
        setImageFile(file)
        setLoadingImageFile(false)
      } else if (imageFile === null && imageFileSecond !== null) {
        if (file.name !== imageFileSecond.name) {
          console.log(file.name !== imageFileSecond.name);
          setImageFile(file)
        } else {
          console.log(file.name !== imageFileSecond.name);
          setErrorMessage((prev) => ([...prev, errorMessage[0].error = true]))
        }
      } else if (file?.name !== imageFile?.name) {
        setImageFileSecond(file)
        setLoadingImageFile(false)
      } else {
        setLoadingImageFile(false)
        setErrorMessage((prev) => ([...prev, errorMessage[0].error = true]))
      }
      convertFileToBase64(await file, res => {
        if (file.name.split('.').pop() === 'png') {
          if (frontImageId === '' || frontImageId === null) {
            setFrontImageId(res)
            setLoadingImageFile(false)
          }
          else if (backImageId === null || backImageId === '') {
            setBackImageId(res)
            setLoadingImageFile(false)
          }
        } else if (file.name.split('.').pop() === 'jpg') {
          if (frontImageId === '' || frontImageId === null) {
            setFrontImageId(res)
            setLoadingImageFile(false)
          }
          else if (backImageId === null || backImageId === '') {
            setBackImageId(res)
            setLoadingImageFile(false)
          }
        } else if (file.name.split('.').pop() === 'pdf') {
          if (frontImageId === '' || frontImageId === null) {
            setFrontImageId(res)
            setLoadingImageFile(false)
          }
        }
        setLoadingImageFile(false)
      })
      setLoadingImageFile(false)
    }

    const handleDelete = () => {
      setImageFile(null)
      setFrontImageId(null)
    }

    const handleDeleteSecond = () => {
      setImageFileSecond(null)
      setBackImageId(null)
    }

    const convertFileToBase64 = (file, cb) => {
      const reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        cb(reader.result)
      }
    }



    const HandleFileFormat = () => {
      // const helper = imageFile?.name
      // const fileChecker = helper?.split('.').pop()
      // if (
      //   fileChecker !== 'png' &&
      //   fileChecker !== 'jpg' &&
      //   fileChecker !== 'pdf'
      // ) {
      //   setImageFile(null)
      //   setFrontImageId(null)
      //   // setErrorMessage(true)
      //   return null
      // } else 
      if (loadingImageFile === false) {
        // setErrorMessage(false)
        return (
          <div style={styles.uploadPhotoContainer}>
            <div style={styles.uploadPhotoContainerChild}>
              <div style={styles.uploadedImage}>
                <img src={imageBeforeFileName} alt='' size={20} />
                <div
                  style={styles.iconPosition}>
                  <Text text={`${imageFile?.name.length > 20 ? imageFile?.name.slice(0, 20) + '...' : imageFile?.name}`} style={styles.text} />
                </div>
              </div>
              <img
                src={closeIconRed}
                size={20}
                color={Colors.gray}
                alt=''
                onClick={handleDelete}
                style={styles.cancelUpload}
              />
            </div>
          </div>
        )
      } else {
        return <Text i18nKey={'Loading...'} />
      }
    }
    const HandleFileFormatSecondary = () => {
      // const helper = imageFile?.name
      // const fileChecker = helper?.split('.').pop()
      // if (
      //   fileChecker !== 'png' &&
      //   fileChecker !== 'jpg' &&
      //   fileChecker !== 'pdf'
      // ) {
      //   setImageFile(null)
      //   setFrontImageId(null)
      //   // setErrorMessage(true)
      //   return null
      // } else 
      if (loadingImageFile === false) {
        // setErrorMessage(false)
        return (
          <div style={styles.uploadPhotoContainer}>
            <div style={styles.uploadPhotoContainerChild}>
              <div style={styles.uploadedImage}>
                <img src={imageBeforeFileName} alt='' size={20} />
                <div
                  style={styles.iconPosition}>
                  <Text text={`${imageFileSecond?.name.length > 20 ? imageFileSecond?.name.slice(0, 20) + '...' : imageFileSecond?.name}`} style={styles.text} />
                </div>
              </div>
              <img
                src={closeIconRed}
                size={20}
                color={Colors.gray}
                alt=''
                onClick={handleDeleteSecond}
                style={styles.cancelUpload}
              />
            </div>
          </div>
        )
      } else {
        return <Text i18nKey={'Loading...'} />
      }
    }


    return (
      <div style={styles.bigContainerUploadImage} onDragEnter={handleDrag}>

        {imageFile !== null && imageFileSecond !== null ? null : (
          <label htmlFor="fileinput">
            <div style={Dimension.width > 500 ? styles.uploadImageContainer : styles.uploadImageContainerLowerPadding}>
              <img src={uploadImage} alt='' size={24} />
              <div style={styles.textContainer}>
                <Text text={'Drag and drop here '} size={'note'} color={'#616161'} style={{ width: 'auto' }} />
                <Text text={'or browse'} size={'note'} style={{ width: 'auto' }} />
              </div>
              <input
                type="file"
                // capture="camera"
                id="fileinput"
                multiple={false}
                ref={fileInputRef}
                onClick={handleLabelClick}
                onChange={handleFileChange}
                accept="image/png, image/jpeg,application/pdf"
                style={styles.inputImage}
              />
              {dragActive && <div style={styles.dragNdrop} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
            </div>
          </label>
        )}
        {imageFile !== null || imageFileSecond !== null ? (
          <>
            {imageFile !== null ? <HandleFileFormat /> : null}
            {imageFileSecond !== null ? <HandleFileFormatSecondary /> : null}
          </>
        ) : null}

        {errorMessage[1].error ? (
          <Text
            text={errorMessage[1].errorMessage}
            color={Colors.accent}
            style={{ width: 'max-content' }}
          />
        ) : null}
        {errorMessage[0].error ? (
          <Text
            text={errorMessage[0].errorMessage}
            color={Colors.accent}
            style={{ width: 'max-content' }}
          />
        ) : null}
        {errorMessage[2].error ? (
          <Text
            text={errorMessage[2].errorMessage}
            color={Colors.accent}
            style={{ width: 'max-content' }}
          />
        ) : null}

      </div>
    )
  }


  return (
    <RenderImageInput />
  )
}

UploadFile.propTypes = {
  imageFile: PropTypes.any,
  imageFileSecond: PropTypes.any,
  setImageFileSecond: PropTypes.func,
  setImageFile: PropTypes.func,
  setFrontImageId: PropTypes.func,
  frontImageId: PropTypes.string,
  setBackImageId: PropTypes.func,
  backImageId: PropTypes.string
}
export default React.memo(UploadFile)