import Colors from "../../utils/colors/Colors"
import Icon from '../../utils/images/Misc_03.svg'
import OrangeIconLi from '../../utils/images/OrangeIconLi.svg'

const CardComponentStyles = {
    card: {
        desktop: {
            container: {
                display: 'flex',
                flexDirection: 'row',
                gap: 32,
                alignSelf: 'center',
                width: '100%',
                maxWidth:1400
            },
            text: {
                textAlign: 'center',
                alignSelf: 'center',
                width: 'auto',
                lineHeight: 'normal'
            },
            defaultStyles: {
                position: 'relative',
                display: 'flex',
                flex: 1,
                width: 200,
                height: 88,
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: 6,
                border: '1px solid #000',
                boxShadow: '4px 4px 0px 0px #000',
                background: Colors.white,
                cursor: 'pointer',
            },
            isFilled: {
                background: Colors.purpleC,
                color: Colors.white,
            },
            image: {
                width: 72,
                height: 'auto',
                position: 'absolute',
                top: '-47%',
                objectFit: 'cover'
            },
            shadowImage: {
                width: '149px',
                height: 'auto',
                zIndex: -1,
                position: 'absolute',
                top: '-94%',
                left: '25%',
            },
        },
        laptop: {
            container: {
                display: 'flex',
                flexDirection: 'row',
                gap: 32,
                width: '100%',
            },
            container2: {
                display: 'flex',
                flexDirection: 'row',
                gap: 16,
            },
            text: {
                textAlign: 'center',
                padding: '0px 24px',
                flex: 1,
                alignSelf: 'center',
                width: 'auto',
                lineHeight: 'normal'
            },
            defaultStyles: {
                position: 'relative',
                display: 'flex',
                flex: 1,
                height: 88,
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: 16,
                flexShrink: 0,
                borderRadius: 6,
                border: '1px solid #000',
                boxShadow: '4px 4px 0px 0px #000',
                background: Colors.white,
                cursor: 'pointer'
            },
            isFilled: {
                background: Colors.purpleC,
                color: Colors.white,
            },
            image: {
                width: 65,
                height: 'auto',
                position: 'absolute',
                top: '-43%',
                objectFit: 'cover'
            },
            shadowImage: {
                width: '101.76px',
                height: '131.433px',
                zIndex: -1,
                position: 'absolute',
                top: '-96%',
            },

        },
        mobile: {
            container: {
                display: 'flex',
                flexDirection: 'row',
                gap: 32,
                overflowX: 'scroll',
                padding: '60px 0px 10px 0px',
                flexGrow: 1,
                alignItems: 'center',
                marginTop: -38,
                zIndex: -1,
                paddingRight: 4,
                scrollBehavior: 'smooth'
            },
            defaultStyles: {
                position: 'relative',
                display: 'flex',
                flex: 1,
                height: 76,
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: 16,
                flexShrink: 0,
                borderRadius: 6,
                border: '1px solid #000',
                boxShadow: '4px 4px 0px 0px #000',
                background: Colors.white,
            },
            isFilled: {
                background: Colors.purpleC,
                color: Colors.white,
            },
            text: {
                textAlign: 'center',
                padding: '0px 60px',
                width: 'max-content',
                lineHeight: 'normal'
            },
            image: {
                width: 57,
                height: 'auto',
                position: 'absolute',
                top: '-47%',
                objectFit: 'cover'
            },
            shadowImage: {
                width: 78,
                height: 63,
                zIndex: -1,
                position: 'absolute',
                top: '-63%',
                left: '24%',
            },
        },
    },
    cardProgram: {
        desktop: {
            TopCardProgramContainer: {
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 50,
            },
            CardProgramContainer: {
                display: 'flex',
                flex: 1,
                alignSelf: 'center',
                width: '100%',
                maxWidth:1400
            },
            defaultStyles: {
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: 'fit-content',

            },
            image: {
                width: '40%',
                height: '400px',
                flexShrink: 0,
                borderRadius: 6,
                display: 'flex',
                flex: 1,
                border: '1px solid rgb(119, 80, 191)',
                background: 'rgb(255, 255, 255)',
                boxShadow: 'rgb(0, 0, 0) 4px 4px 0px 0px',
                objectFit: 'cover',
                zIndex: 1,
            },
            textContentStyles: {
                display: 'flex',
                flex: 1,
                width: 'auto',
                height: '320px',
                alignSelf: 'center',
                padding: 32,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: 6,
                border: '1px solid #7750BF',
                background: '#FFF',
                boxShadow: '4px 4px 0px 0px #000',
                borderLeft: 'none',
                marginLeft: -5,
                gap: 12,
            },
            textContentStyles2: {
                display: 'flex',
                flex: 1,
                width: 'auto',
                height: '320px',
                alignSelf: 'center',
                padding: 32,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: 6,
                border: '1px solid #7750BF',
                background: '#FFF',
                boxShadow: '4px 4px 0px 0px #000',
                borderLeft: 'none',
                marginLeft: -5,
                gap: 12,
            },
            bulletListContainer: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                margin: 0,
                gap: 8,
            },
            bulletListIcon: {
                listStyleImage: `url(${Icon})`,
                fontSize: '16px',
                fontFamily: 'Open Sans',
            },
            bulletListIconOrange: {
                listStyleImage: `url(${OrangeIconLi})`,
                fontSize: '16px',
                fontFamily: 'Open Sans',
            },
            description: {
                lineHeight: 1.5,
            },
            trippleLinesContainer: {
                position: 'relative',
                display: 'flex',
            },
            WowLogo: {
                position: 'absolute',
                width: 21,
                height: 65,
                top: -45,
                left: -53,
            },
            descriptionContainer: {
                display: 'flex',
                flexDirection: 'column',
                gap: 32
            }
        },
        laptop: {
            TopCardProgramContainer: {
                display: 'flex',
                width: '100%',
            },
            CardProgramContainer: {
                display: 'flex',
                width: '100%',
                // maxWidth:1000
            },
            defaultStyles: {
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
            },
            image: {
                width: '30%',
                maxWidth:450,
                height: '400px',
                borderRadius: 6,
                display: 'flex',
                flex: 1,
                border: '1px solid rgb(119, 80, 191)',
                background: 'rgb(255, 255, 255)',
                boxShadow: 'rgb(0, 0, 0) 4px 4px 0px 0px',
                objectFit: 'cover',
                zIndex: 1,
            },
            textContentStyles: {
                display: 'flex',
                flex: 1,
                alignSelf: 'center',
                padding: 24,
                height: '320px',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: 6,
                border: '1px solid #7750BF',
                background: '#FFF',
                boxShadow: '4px 4px 0px 0px #000',
                marginLeft: -5,
                gap: 8,
            },
            textContentStyles2: {
                display: 'flex',
                flex: 1,
                alignSelf: 'center',
                padding: 24,
                height: '320px',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: 6,
                border: '1px solid #7750BF',
                background: '#FFF',
                boxShadow: '4px 4px 0px 0px #000',
                marginLeft: -5,
                gap: 8,
            },
            bulletListContainer: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                margin: 0,
                gap: 8,
            },
            bulletListIcon: {
                listStyleImage: `url(${Icon})`,
                fontSize: '16px',
                fontFamily: 'Open Sans',
            },
            bulletListIconOrange: {
                listStyleImage: `url(${OrangeIconLi})`,
                fontSize: '16px',
                fontFamily: 'Open Sans',
            },
            description: {
                lineHeight: 1.5,
            },
            trippleLinesContainer: {
                position: 'relative',
                display: 'flex',
            },
            WowLogo: {
                position: 'absolute',
                width: 21,
                height: 65,
                transform: 'rotate(10deg)',
                left: -23,
                padding: '16px 0px',
            },
            descriptionContainer: {
                display: 'flex',
                flexDirection: 'column',
                gap: 32
            }

        },
        tablet: {
            CardProgramContainer: {
                display: 'flex',
                width: '100%',
            },
            TopCardProgramContainer: {
                display: 'flex',
                flexDirection: 'row',
                marginTop: 32,
                justifyContent: 'center',
                width: '100%',
            },
            defaultStyles: {
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
            },
            image: {
                width: '50%',
                height: '420px',
                borderRadius: 6,
                border: '1px solid rgb(119, 80, 191)',
                background: 'rgb(255, 255, 255)',
                boxShadow: 'rgb(0, 0, 0) 4px 4px 0px 0px',
                objectFit: 'cover',
                zIndex: 1,

            },
            textContentStyles: {
                display: 'flex',
                flex: 1,
                width: '75%',
                height: '350px',
                alignSelf: 'center',
                padding: 18,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexShrink: 0,
                marginLeft: -1,
                borderRadius: 6,
                border: '1px solid #7750BF',
                boxShadow: '4px 4px 0px 0px #000',
                background: Colors.white,
                gap: 8,
            },
            textContentStyles2: {
                display: 'flex',
                flex: 1,
                width: '75%',
                height: '350px',
                alignSelf: 'center',
                padding: 18,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexShrink: 0,
                marginLeft: -1,
                borderRadius: 6,
                border: '1px solid #7750BF',
                boxShadow: '4px 4px 0px 0px #000',
                background: Colors.white,
                gap: 8,
            },
            bulletListContainer: {
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: '100%',
                margin: 0,
                gap: 8,
                marginTop: 12,
            },
            bulletListIcon: {
                listStyleImage: `url(${Icon})`,
                fontSize: '16px',
                fontFamily: 'Open Sans',
            },
            bulletListIconOrange: {
                listStyleImage: `url(${OrangeIconLi})`,
                fontSize: '16px',
                fontFamily: 'Open Sans',
            },
            description: {
                lineHeight: 1.3,
                marginTop: 8,
                marginBottom: 16,
                display: 'flex',
                flex: 1,
            },

            trippleLinesContainer: {
                position: 'relative',
                display: 'flex',
            },
            WowLogo: {
                position: 'absolute',
                width: 13,
                height: 42,
                transform: 'rotate(10deg)',
                top: -53
            },
            descriptionContainer: {
                display: 'flex',
                flexDirection: 'column',
                gap: 16
            }
        },
        mobile: {
            CardProgramContainer: {
                display: 'flex',
                flex: 1,
                width: '100%',
                alignSelf: 'flex-start',
            },
            defaultStyles: {
                display: 'flex',
                flexDirection: 'column-reverse',
                width: '100%',
                alignItems: 'center',
            },
            image: {
                width: '100%',
                maxWidth:'400px',
                height: 'auto',
                borderRadius: 6,
                border: '1px solid rgb(119, 80, 191)',
                background: 'rgb(255, 255, 255)',
                boxShadow: 'rgb(0, 0, 0) 4px 4px 0px 0px',
                objectFit: 'cover',
                zIndex: 1,
            },
            textContentStyles: {
                display: 'flex',
                flex: 1,
                width: '80%',
                maxWidth:'320px',
                alignSelf: 'center',
                padding: 16,
                flexDirection: 'column',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: 6,
                border: '1px solid #7750BF',
                boxShadow: '4px 4px 0px 0px #000',
                background: Colors.white,

            },
            textContentStyles2: {
                display: 'flex',
                flex: 1,
                width: '80%',
                maxWidth:'320px',
                alignSelf: 'center',
                padding: 16,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: 6,
                border: '1px solid #7750BF',
                boxShadow: '4px 4px 0px 0px #000',
                background: Colors.white,

            },
            bulletListContainer: {
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: '100%',
                margin: 0,
                gap: 8,
                marginTop: 8,
            },
            bulletListIcon: {
                listStyleImage: `url(${Icon})`,
                fontFamily: 'Open Sans',
                fontSize: '16px',
            },
            bulletListIconOrange: {
                listStyleImage: `url(${OrangeIconLi})`,
                fontFamily: 'Open Sans',
                fontSize: '16px',
            },
            description: {
                lineHeight: 1.25,
                marginTop: 8,
                marginBottom: 16,
                display: 'flex',
                flex: 1,
            },

            TopCardProgramContainer: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
            },
            trippleLinesContainer: {
                position: 'relative',
                display: 'flex',
            },
            WowLogo: {
                position: 'absolute',
                width: 13,
                height: 42,
                transform: 'rotate(10deg)',
                top: -45,
                left: -40,
            },
            descriptionContainer: {
                display: 'flex',
                flexDirection: 'column',
                gap: 8
            }
        },
    },


}

export default CardComponentStyles