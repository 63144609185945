import React, { useState } from 'react'
import ExpandableTextFieldStyles from './ExpandableTextField.Styles'
import Text from '../Text/Text'
import downArrow from '../../utils/images/downArrow.svg'
import PropTypes from 'prop-types'
import '../index.css'
import { motion, AnimatePresence } from 'framer-motion'

const ExpandableTextField = props => {
  const styles = { ...ExpandableTextFieldStyles }

  const {
    options,
    textSize,
    style,
  } = props

  const [selected, setSelected] = useState([options[0]])

  const handleExpanding = (item) => {
    if (selected.includes(item)) {
      setSelected(prev => {
        const newState = prev.filter((items) => items !== item)
        return newState
      })
    } else {
      setSelected(prev => ([...prev, item]))
    }
  }

  return (
    <div style={styles.container}>
      {
        options.map((item, index) => {
          const allStyles = Object.assign({},
            styles.defaultStyles,
            style
          );
          const gap = selected ? 16 : 12
          return (
            <AnimatePresence mode='wait' key={index}>
              <motion.div
                onClick={() => handleExpanding(item, index)}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.3 }}
                style={allStyles}
              >
                <div style={styles.rowContainer(gap)} className='animationIn'>
                  <Text text={item.question} size={textSize} textWeight={'bold'} align={'left'} style={{ lineHeight: 1.5 }} />
                  <img src={downArrow} alt='' style={selected.includes(item) ? styles.upArrow : styles.downArrow} />
                </div>
                {
                  selected.includes(item) &&
                  <motion.div
                    initial={{ y: -10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Text key={index} text={item.answer} size={'note'} align={'left'} style={styles.answerStyles} />
                  </motion.div>
                }
                <div style={{ width: '100%', border: '1px solid #000' }} />
              </motion.div>
            </AnimatePresence>
          )
        })
      }
    </div>
  )
}

ExpandableTextField.propTypes = {
  options: PropTypes.array,
  textSize: PropTypes.string,

  style: PropTypes.oneOf([
    PropTypes.array,
    PropTypes.object
  ])
}

ExpandableTextField.defaultProps = {
  onClicks: () => { },
  textSize: 'body'
}

export default React.memo(ExpandableTextField)