import React from 'react'
import ButtonStyles from './Button.Styles.js'
import PropTypes from 'prop-types'
import Text from '../Text/Text.js'
import useWindowDimensions from '../../hooks/useWindowDimensions.js'


const Button = props => {
  const Dimension = useWindowDimensions()


  const styles = Dimension.width > 1440 ? { ...ButtonStyles.desktop } : Dimension.width > 1023 && Dimension.height < 1441 ? { ...ButtonStyles.laptop } : { ...ButtonStyles.mobile }


  const {
    text,
    textWeight,
    isFilled,
    isBorderThickened,
    onClick,
    style
  } = props


  const allStyles = Object.assign({},
    styles.defaultProps, isFilled ? styles.filledStyle : null,
    isBorderThickened ? styles.borderStyle : null, textWeight === 'light' ? styles.textColor : null, style
  );

  return (
    <div style={allStyles}
      onClick={() => onClick()}>
      <Text text={text} textWeight={textWeight} />
    </div>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  textWeight: PropTypes.oneOf(['light', 'regular', 'bold', 'extraBold']),
  isFilled: PropTypes.bool,
  isBorderThickened: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}

Button.defaultProps = {
  text: '',
  textWeight: 'normal',
  isFilled: false,
  isBorderThickened: false,
  onClick: () => { },
  style: []
}


export default Button