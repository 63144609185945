import React, { useEffect, useState } from 'react'
import ContactStyles from './Contact.Styles'
import TaleasLogo from '../../utils/images/TaleasLogo.svg'
import Text from '../../components/Text/Text'
import InputField from '../../components/InputField/InputField'
import TextArea from '../../components/TextArea/TextArea'
import UploadFile from '../../components/UploadFile/UploadFile'
import Colors from '../../utils/colors/Colors'
import RedStars from '../../utils/images/RedStars.svg'
import YellowStar from '../../utils/images/YellowStar.svg'
import Smile from '../../utils/images/Smiley.svg'
import SpringArrow from '../../utils/images/SpringArrow.svg'
import TrippleLines from '../../utils/images/TrippleLines.svg'
import RedStar from '../../utils/images/RedStar.svg'
import TaleasPhoto from '../../utils/images/TaleasPhoto.png'
// import TaleasBook from '../../utils/images/ResizedBook.png'
import FirstLine from '../../utils/images/FirstLine.svg'
import navbarOpener from '../../utils/images/navbarOpener.svg'
import SecondLine from '../../utils/images/SecondLine.svg'
import fixesImageContactSize from '../../utils/images/fixesImageContactSize.png'
import closeIconWhite from '../../utils/images/closeIconWhite.svg'
import formMessageIcon from '../../utils/images/formMessageIcon.svg'
import formMessageIcon2 from '../../utils/images/formMessageIcon2.svg'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import '../index.css'
import { useNavigate } from 'react-router-dom'
import { contactInformation, filePostService, imageService } from '../../Services/Contact'
import { Buffer } from 'buffer';
import { MoonLoader } from 'react-spinners'
import { useGameManager } from '../../TaleasMaze/context/GameManager'



// eslint-disable-next-line react/prop-types
const Contact = ({ showNavbar, setShowNavbar, id }) => {
  const Dimension = useWindowDimensions()
  const navigate = useNavigate()
  //  console.log(id)
  const { gameState, setGameState, score, time } = useGameManager();



  const styles = Dimension.width > 1440 ? { ...ContactStyles.desktop } : Dimension.width > 1023 && Dimension.height < 1440 ?
    { ...ContactStyles.laptop } : Dimension.width > 767 && Dimension.height < 1024 ? { ...ContactStyles.tablet } : { ...ContactStyles.mobile }

  const handleRoutingHome = () => {
    navigate('/')
    setShowNavbar(false)
  }

  const [name, setName] = useState('')
  const [study, setStudy] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [description, setDescription] = useState('')
  const [formSuccess, setFormSuccess] = useState(false)
  const [emailError, setEmailError] = useState(true)
  const [numberError, setNumberError] = useState(true)
  const [imageFile, setImageFile] = useState(null)
  const [imageFileSecond, setImageFileSecond] = useState(null)
  const [frontImageId, setFrontImageId] = useState('')
  const [backImageId, setBackImageId] = useState('')
  const [submitForm, setSubmitForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [condition, setCondition] = useState(false)
  const [firstUrl, setFirstUrl] = useState('')
  const [secondUrl, setSecondUrl] = useState('')
  const [imageHelperState, setImageHelperState] = useState(false)
  const [nameError, setNameError] = useState(true)



  const handleChangeName = (e) => {
    setName(e.target.value)
  }

  const handleChangeStudy = (e) => {
    setStudy(e.target.value)
  }
  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleChangeNumber = (e) => {
    setPhoneNumber(e.target.value)
  }

  const handleChangeTextArea = (e) => {
    setDescription(e.target.value)
  }

  const validateName = () => {
    const newName = name.trim()
    return setNameError(/^[a-zA-Z]{2,50}(?: [a-zA-Z]+){0,}$/.test(newName))
  }

  const validateEmail = () => {
    return setEmailError(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/.test(email));

  }

  const validatePhoneNumber = () => {
    if (phoneNumber.slice(0, 1) === '+') {
      return setNumberError(/^[+\d]?(?:[\d-.\s()]*)$/.test(phoneNumber))
    } else {
      return setNumberError(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phoneNumber))
    }
  }


  const inputData = [
    {
      text: 'Name',
      placeHolder: 'Your Name',
      onChange: handleChangeName,
      value: name,
      onBlur: validateName,
      errorText: 'Enter a valid name',
      errorType: nameError
    },
    {
      text: 'What do you study now?',
      placeHolder: 'If you are studying',
      onChange: handleChangeStudy,
      value: study,
    },
    {
      text: 'Your email',
      placeHolder: 'Your email',
      onChange: handleChangeEmail,
      value: email,
      onBlur: validateEmail,
      errorText: 'Enter a valid email address',
      errorType: emailError
    },
    {
      text: 'How to contact you?',
      placeHolder: 'Phone number',
      onChange: handleChangeNumber,
      value: phoneNumber,
      onBlur: validatePhoneNumber,
      errorText: 'Enter a valid phone number',
      errorType: numberError
    }
  ]

  const handleResetForm = () => {
    setName('')
    setStudy('')
    setEmail('')
    setPhoneNumber('')
    setDescription('')
    setImageFile(null)
    setImageFileSecond(null)
    setFrontImageId('')
    setBackImageId('')
    setFirstUrl('')
    setSecondUrl('')
  }

  const handleImageServices = async () => {
    try {
      if (imageFile !== null) {
        const dataToBePassed = {
          'filename': `${imageFile?.name.replaceAll(' ', '_')}`,
          name: name,
          applicationId: id,
        }
        localStorage.setItem("aplicationIdLocal", id)
        await filePostService(dataToBePassed).then((dataImage) => { imageSuccessCallback(dataImage), imageErrorCallback })
      }
    } catch (error) {
      console.error(error)
    }

  }

  const imageSuccessCallback = (dataImage) => {
    setFirstUrl(dataImage?.attachmentURL)
    const signedUrl = dataImage?.signedURL

    let data;
    if (frontImageId.startsWith("data:image/")) {
      data = frontImageId.replace(/^data:image\/\w+;base64,/, '')
    } else {
      data = frontImageId.replace(/^data:application\/\w+;base64,/, '')
    }
    const decodedLogo = Buffer.from(data, 'base64')
    imageService(signedUrl, decodedLogo)
  }

  const imageErrorCallback = () => {
    console.error('A problem occurred while uploading your image.')
  }


  const handleImageServicesSecond = async () => {
    try {
      if (imageFileSecond !== null) {
        const dataToBePassed = {
          'filename': `${imageFileSecond?.name.replaceAll(' ', '_')}`,
          name: name,
          applicationId: id,
        }
        await filePostService(dataToBePassed).then((dataImage2) => { secondImageSuccessCallback(dataImage2), secondImageErrorCallback })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const secondImageSuccessCallback = (dataImage2) => {
    setSecondUrl(dataImage2?.attachmentURL)
    const signedUrl = dataImage2?.signedURL
    const data = backImageId.replace(/^data:image\/\w+;base64,/, '')
    const decodedLogo = Buffer.from(data, 'base64')
    imageService(signedUrl, decodedLogo)
  }

  const secondImageErrorCallback = () => {
    console.error('A problem occurred while uploading your second image.')
  }

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      if (imageFile !== null && imageFileSecond !== null) {
        await Promise.all([handleImageServices(imageFile, frontImageId), handleImageServicesSecond(imageFileSecond, backImageId)])
        setImageHelperState(true)
      } else if (imageFile !== null) {
        await handleImageServices(imageFile, frontImageId)
        setImageHelperState(true)
      } else if (imageFileSecond !== null) {
        await handleImageServicesSecond(imageFileSecond, backImageId)
        setImageHelperState(true)
      }
      setImageHelperState(true)

    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (imageHelperState) {
      _contactInformation()
    }
  }, [imageHelperState])




  useEffect(() => {
    setCondition(name !== '' && email !== '' && study !== '' && phoneNumber !== '' && description !== '' && emailError && numberError)
  }, [name, email, study, phoneNumber, description, emailError, numberError])


  const formatTime = timeInMilliseconds => {
    const minutes = Math.floor(timeInMilliseconds / (60 * 1000));
    const seconds = Math.floor((timeInMilliseconds % (60 * 1000)) / 1000);
    const milliseconds = Math.floor((timeInMilliseconds % 1000) / 10);

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    const formattedMilliseconds = String(milliseconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
  };


  const _contactInformation = async () => {
    try {
      const allData = {
        name: name.trim().replace(/\s+/g, '_'),
        phoneNumber: phoneNumber,
        email: email,
        study: study,
        taleasMazeScore: score.toString(),
        taleasMazeTime: formatTime(time).toString(),
        description: description,
        ...(firstUrl !== '' && secondUrl === '' ? { 'attachments': [firstUrl] } : {}),
        ...(secondUrl !== '' && firstUrl === '' ? { 'attachments': [secondUrl] } : {}),
        ...(firstUrl !== '' && secondUrl !== '' ? { 'attachments': [firstUrl, secondUrl] } : {})
      }
      console.log("BEFORE SUBMIT")
      localStorage.setItem("name", name)
      localStorage.setItem("taleasMazeScore", score)
      await contactInformation(allData).then(successCallback, failureCallback)
      if (gameState >= 5) {
        setGameState(6)
        navigate('/game')
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const successCallback = () => {
    setFormSuccess(true)
    handleResetForm()
    setSubmitForm(true)
    setIsLoading(false)
    setImageHelperState(false)
  }

  const failureCallback = () => {
    setFormSuccess(false)
    handleResetForm()
    setSubmitForm(true)
    setIsLoading(false)
    setImageHelperState(false)
  }

  if (submitForm) {
    setTimeout(() => setSubmitForm(false), 5000)
  }


  const FormMessage = () => {
    return (
      <div style={formSuccess ? styles.formMessageStyles : styles.formMessageStylesError}>
        <div style={styles.innerFormMessageContainer}>
          <div style={styles.rowContainerformMessageStyles}>
            <img src={formSuccess ? formMessageIcon : formMessageIcon2} alt='Message icon describing the form completion!' style={styles.formMessageIcon} />
            <Text style={styles.textError} text={formSuccess ? 'Form sent successfully!' : 'Something went wrong. Please try again later!'} align={'left'} size={'note'} color={Colors.white} />
          </div>
          <img src={closeIconWhite} alt='Message icon describing the form completion!' onClick={() => setSubmitForm(false)} style={styles.formMessageIcon} />
        </div>
      </div>
    )
  }



  const ImageComponent = () => {
    return (
      <>
        {Dimension.width > 1023 ? <div style={styles.bigImageContainer}>
          <div style={styles.spaceContainer}>
            <div style={styles.doubleImageContainer}>
              <img src={RedStars} alt='a decorative icon to complement taleas image' style={styles.redStarsLogo} />
              <img src={YellowStar} alt='a decorative icon to complement taleas image' style={styles.yellowStarLogo} />
            </div>
            <img src={TaleasPhoto} alt='a photo of all ex taleas members in final presentation' style={Dimension.width < 1600 ? styles.smallerTaleasPhoto : styles.taleasPhotoLogo} />
            <img src={Smile} alt='an icon to give the page more exicting and good vibes' style={styles.smileLogo} />
          </div>
          <div style={styles.bookContainer}>
            <img src={RedStar} alt='a decorative icon to complement taleas image' style={styles.redStarLogo} />
            {/* <img src={TaleasBook} alt='' style={styles.taleasBookLogo} /> */}
          </div>
        </div> : <div style={styles.bottomImagesContainer}>
          <img src={RedStars} alt='a decorative icon to complement taleas image' style={styles.redStarsLogo} />
          <div style={styles.taleasPhoto}>
            <img src={FirstLine} alt='a decorative icon to complement taleas image' style={styles.FirstLine} />
            <img src={SecondLine} alt='a decorative icon to complement taleas image' style={styles.SecondLine} />
            <img src={fixesImageContactSize} alt='a decorative icon to complement taleas image' style={styles.PhotoInsideLines} />
          </div>
        </div>}
      </>
    )
  }
  return (
    <>
      {Dimension.width < 1024 && showNavbar ? null :
        <div style={Dimension.height < 800 ? styles.wrapper2 : Dimension.width < 1600 && Dimension.width > 1440 ? styles.wrapper3 : styles.wrapper} className='wrapper'>
          {Dimension.width < 1024 ? <div style={styles.topIconsRow}>
            <img src={TaleasLogo} alt='a logo that identifies taleas' style={styles.taleasLogo} onClick={handleRoutingHome} />
            <img src={navbarOpener} alt='an icon to help with the functionality of opening navbar' style={styles.freeLogo} onClick={() => setShowNavbar((true))} />
          </div>
            : null
          }
          <div style={styles.bigContainerBigScreenHandle}>

            <Text text={'Become a part of us'} size={'h3'} textWeight={'bold'} align={'left'} style={styles.textBecomePartOfUs} />
            <div style={styles.innerContainer}>
              <form style={styles.innerContainer2} id='create-course-form'>
                <div style={styles.inputFieldContainer} >
                  <div style={styles.rowInputContainer}>
                    {inputData.slice(0, 2).map((input, index) => {
                      return (
                        <div key={index} style={styles.inputField}>
                          <Text text={input.text} size={'note'} align={'left'} />
                          <InputField placeHolder={input.placeHolder} style={styles.inputFieldFix} onChange={input.onChange} value={input.value} onBlur={input.onBlur} />
                          {!input.errorType && <Text text={input.errorText} size={'note'} align={'left'} color={Colors.accent} style={{ width: 'auto' }} />}
                        </div>
                      )
                    })}
                  </div>
                  <div style={styles.rowInputContainer}>
                    {inputData.slice(2).map((input, index) => {
                      return (
                        <div key={index} style={input.placeHolder === 'Your email' ? styles.inputField2 : styles.inputField}>
                          <Text text={input.text} size={'note'} align={'left'} />
                          <InputField placeHolder={input.placeHolder} style={styles.inputFieldFix} onChange={input.onChange} value={input.value} onBlur={input.onBlur} />
                          {!input.errorType && <Text text={input.errorText} size={'note'} align={'left'} color={Colors.accent} style={{ width: 'auto' }} />}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div style={styles.textAreaContainer}>
                  <Text text={'Tell us about yourself and your passions?'} size={'note'} align={'left'} />
                  <TextArea placeHolder={'About you'} style={styles.textArea} onChange={handleChangeTextArea} value={description} />
                  <div>

                  </div>
                </div>
                <div style={styles.uploadFileContainer}>
                  {Dimension.width > 1023 ?
                    <div style={styles.trippleLinesContainer}>
                      <img src={SpringArrow} alt='a decorative arrow that helps on directions of applying' style={styles.springArrowLogo} />
                    </div> : null}
                  {Dimension.width < 1024 ? <div style={styles.smileCvContainer}>
                    <Text text={'Upload your CV or projects'} size={'note'} align={'left'} />
                    <img src={Smile} alt='an icon to give the page more exicting and good vibes' style={styles.smileLogo} />
                  </div> :
                    <Text text={'Upload your CV or projects'} size={'note'} align={'left'} />
                  }
                  <div style={styles.uploadFileRowContainer}>
                    <UploadFile
                      imageFile={imageFile}
                      frontImageId={frontImageId}
                      backImageId={backImageId}
                      imageFileSecond={imageFileSecond}
                      setImageFile={setImageFile}
                      setImageFileSecond={setImageFileSecond}
                      setFrontImageId={setFrontImageId}
                      setBackImageId={setBackImageId}
                    />
                  </div>
                  <Text text={'File(s) size limit is 10MB.'} textWeight={'regular'} color={Colors.greyText} size={'footnote'} align={'left'} />
                </div>
                <div style={styles.buttonContainer}>
                  <div style={styles.button} onClick={() => handleResetForm()}>Reset</div>
                  {Dimension.width > 1023 ?
                    <>
                      <div style={condition ? styles.buttonF : styles.buttonDisabled} onClick={condition ? onSubmit : () => { }}>
                        {isLoading ? <MoonLoader size={24} color={Colors.white} /> : 'Apply'}
                      </div>
                      <div style={styles.trippleLinesContainer}>
                        <img src={TrippleLines} alt='a decorative line to give text a better look' style={styles.trippleLinesLogo} />
                      </div>
                    </> :
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '-webkit-fill-available' }}>
                      <div style={condition ? styles.buttonF : styles.buttonDisabled} onClick={condition ? onSubmit : () => { }}>
                        {isLoading ? <MoonLoader size={24} color={Colors.white} /> : 'Apply'}
                      </div>
                      <div style={styles.trippleLinesContainer}>
                        <img src={TrippleLines} alt='a decorative line to give text a better look' style={styles.trippleLinesLogo} />
                      </div> </div>}
                </div>
              </form>
              <ImageComponent />
            </div>

          </div>
          {submitForm && <FormMessage />}
        </div>
      }

    </>
  )
}

export default Contact