import React, { useState } from 'react'
import AboutStyles from './About.Styles'
import Text from '../../components/Text/Text'
import TextContainerColored from '../../components/TextContainerColored/TextContainerColored'
import TaleasLogo from '../../utils/images/TaleasLogo.svg'
import PurpleAbout from '../../utils/images/PurpleAbout.svg'
import TrippleLines from '../../utils/images/TrippleLines.svg'
import ElephantLine from '../../utils/images/ElephantLine.svg'
import Colors from '../../utils/colors/Colors'
import ExpandableTextField from '../../components/ExpandableTextField'
import navbarOpener from '../../utils/images/navbarOpener.svg'
import aboutUsImage from '../../utils/images/aboutUsImage.png'
import aboutUsImageMobile from '../../utils/images/aboutUsImageMobile.png'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import '../index.css'
import { useNavigate } from 'react-router-dom'




// eslint-disable-next-line react/prop-types
const About = ({ showNavbar, setShowNavbar }) => {
  const Dimension = useWindowDimensions()
  const navigate = useNavigate()


  const styles = Dimension.width > 1440 ? { ...AboutStyles.desktop } : Dimension.width > 1023 && Dimension.height < 1440 ?
    { ...AboutStyles.laptop } : Dimension.width > 767 && Dimension.height < 1024 ? { ...AboutStyles.tablet } : { ...AboutStyles.mobile }

  const [expandableTextFieldData, setExpandableTextFieldData] = useState([{
    question: 'How long does the program last?',
    answer: 'The program lasts at least 3 months, but contrary to pre-defined structural modules in school, our plans depend entirely on your progress. Since everybody has a different learning curve, that’s what we mainly operate on. Obviously, there will be selections after a certain period of time, based on your dedication and results, but the overall project doesn’t really have a finish line. There’s no limit to improvement!',
    id: 1
  },
  {
    question: 'Do I need any previous work experience to apply?',
    answer: 'No prior work experience is required for application. However, having a demonstrated interest in technology, school and personal projects, relevant coursework, and other technologically related experiences, would be advantageous during the application phase.',
    id: 2
  },
  {
    question: 'How did we get started?',
    answer: 'The foundation started with a simple pilot group called Taleas1. It was the first assembly of exceptional individuals hungry and eager to learn, yet they needed guidance and direction. That’s where the mentors of this project came into place. They helped them grow and understand their craft while also challenging them mentally. The tasks, failures, and sleep deprivation are definitely history!',
    id: 3
  }
  ])


  const handleRoutingHome = () => {
    navigate('/')
    setShowNavbar(false)
  }

  return (
    <>
      {Dimension.width < 1024 && showNavbar ? null : <div style={Dimension.height > 850 ? styles.wrapper : styles.wrapper2} className='wrapper'>
        {Dimension.width < 1024 ? <div style={styles.topIconsRow}>
          <img src={TaleasLogo} alt='a logo that identifies taleas' style={styles.taleasLogo} onClick={handleRoutingHome} />
          <img src={navbarOpener} alt='an icon to help with the functionality of opening navbar' style={styles.freeLogo} onClick={() => setShowNavbar(true)} />
        </div>
          : null
        }
        {Dimension.width > 1023 ?
          <>
            <img src={PurpleAbout} alt='a decorative purple foam to give page a better look' style={styles.PurpleAboutLogo} />
            <img src={ElephantLine} alt='a decorative elephant line to give page a better look' style={styles.ElephantLineLogo} />
          </>
          : null}
        <div style={styles.bigContainerBigScreenHandle}>
          <div style={styles.container}>
            <div style={styles.rowContainerParagraphImage}>
              <div style={styles.innerContainer}>
                <div style={styles.paragraph}>
                  {Dimension.width > 1023 && <div style={styles.trippleLinesContainer}>
                    <img src={TrippleLines} alt='a decorative line to give text a better look' style={styles.ToptrippleLinesLogo} />
                  </div>}
                  <div style={styles.containerAboutTaleas}>
                    <div style={styles.rowContainerFirst}>
                      <Text text={'About'} size={'h3'} style={{ fontWeight: 700, width: 'auto' }} />
                      <TextContainerColored text={'Taleas'} color={Colors.orangeP} size={'h3'} style={styles.coloredText} />
                      {Dimension.width < 1024 && <div style={styles.trippleLinesContainer}>
                        <img src={TrippleLines} alt='a decorative line to give text a better look' style={styles.trippleLinesLogo} />
                      </div>}
                    </div>
                    <Text text={'Getting a grip on your abilities mixed with a splash of confidence is the superb recipe for a thriving individual. Taleas is the educational program that identifies talents and prepares young people for a complex journey towards growth, on a personal and professional level.'}
                      style={{ lineHeight: 1.5, fontSize: 16 }}
                    />
                  </div>
                </div>
                <div style={styles.containerAboutTaleas}>
                  <div style={styles.rowContainerFirst}>
                    <Text text={'Our'} size={'h3'} style={{ fontWeight: 700, width: 'auto' }} />
                    <TextContainerColored text={'Mission'} color={Colors.blueLight} size={'h3'} style={styles.coloredText} />
                    <div style={styles.trippleLinesContainer}>
                      <img src={TrippleLines} alt='a decorative line to give text a better look' style={styles.trippleLinesLogo} />
                    </div>
                  </div>
                  <Text text={'Initially, our core bases were exclusively in the world of technology and innovation. However, limitations don’t really hold solid ground on our principles. With each upcoming group, we’re committed to expand and explore distinct areas, some of them including marketing, design, writing and much more.'}
                    size={'note'}
                    style={{ lineHeight: 1.5, fontSize: 16 }}
                  />
                </div>
              </div>
              {Dimension.width > 1023 && <div style={styles.aboutUsTaleasImageContainer}>
                <img src={aboutUsImageMobile} alt='an image of taleas representives and ex taleas members' style={styles.aboutUsTaleasImage} />
              </div>}
            </div>
            {Dimension.width > 1023 ? <div style={styles.faqStyles}>
              <div style={styles.faqRowContainer}>
                <Text text={'FAQ'} size={'h3'} style={{ fontWeight: 700, width: 'auto' }} align={'left'} />
                <div style={styles.trippleLinesContainer}>
                  <img src={TrippleLines} alt='a decorative line to give text a better look' style={styles.trippleLinesLogo} />
                </div>
              </div>
              <ExpandableTextField options={expandableTextFieldData} onClick={setExpandableTextFieldData} />
            </div> : null}
          </div>
          {Dimension.width < 1024 ? <div style={styles.faqStyles}>
            <div style={styles.faqRowContainer}>
              <Text text={'FAQ'} size={'h3'} style={{ fontWeight: 700, width: 'auto' }} align={'left'} />
              <div style={styles.trippleLinesContainer}>
                <img src={TrippleLines} alt='a decorative line to give text a better look' style={styles.trippleLinesLogo} />
              </div>
            </div>
            <ExpandableTextField options={expandableTextFieldData} textSize={'note'} onClick={setExpandableTextFieldData} />
            <div style={styles.aboutUsTaleasImageContainer}>
              <img src={aboutUsImage} alt='an image of taleas representives and ex taleas members' style={styles.aboutUsTaleasImage} />
            </div>
          </div> : null}
        </div>
      </div>}
    </>
  )
}

export default About